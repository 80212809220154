export { default, applyOptions } from "library/src/weglot/options/options";

export function getSettings() {
  const weglot = window["Weglot"];
  if (
    weglot &&
    ((weglot.options.technology_name &&
      weglot.options.technology_name !== "WordPress") ||
      weglot.options.switcher_editor)
  ) {
    return weglot.options;
  }

  // WordPress
  const pageOptions = document.querySelector("script#weglot-data");
  if (!pageOptions) {
    return;
  }
  const settings = JSON.parse(pageOptions.textContent);
  settings.api_key = "xxxxxxxxxx-xxxxx-xxxxx-xxxxxxxxxx"; // Lib-JS checks for presence of api key, but doesn't use it.
  settings.originalPath = settings.original_path; // Used by JS library isExcludedPath()
  settings.technology_name = "WordPress";
  settings.switchers = settings.custom_settings
    ? settings.custom_settings.switchers
    : [];
  // In Switcher Editor, the lib fetches WordPress settings, automatically finds the
  // <script id="weglot-data">, and adds it to injectedData. So we should only ever
  // use settings.injectedData for WordPress.
  settings.injectedData = { ...settings };

  if (!weglot) {
    window["Weglot"] = {
      options: settings,
    };
  }

  return settings;
}

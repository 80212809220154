import { h } from "preact";
import { useState } from "preact/hooks";

import { getLanguageName, getColor, keycodes } from "../../shared/utils";

import Flag from "../../shared/components/Flag";

const Option = ({
  styleOpts,
  language,
  colors,
  onClick = null,
  url,
  isFocused = false,
  active = false,
  focusRef = null,
  tabIndex,
}) => {
  const { full_name = true, with_flags, flag_type } = styleOpts;
  const languageName = getLanguageName(language);
  const name = full_name ? languageName : language.toUpperCase();
  const [hover, setHover] = useState(false);

  function handleClick(e, l) {
    e.preventDefault();
    if (onClick) {
      onClick(l);
    }
  }

  const displayName = full_name ? name : name.toUpperCase();

  const { backgroundColor, textColor } = getColor(colors, {
    active,
    hover: hover || isFocused,
  });

  return (
    <div
      className={`language-option${active ? " active-language" : ""}`}
      style={{ backgroundColor }}
      {...(active
        ? {
            "aria-label": `Language selected: ${languageName}`,
          }
        : {
            role: "option",
          })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      tabIndex={tabIndex}
      onClick={e => handleClick(e, language)}
      onKeyDown={e => {
        if (e.keyCode === keycodes.enter) {
          handleClick(e, language);
        }
      }}>
      <a
        href={url}
        aria-label={name}
        tabIndex={-1}
        {...(isFocused && !active && { ref: focusRef })}
        style={{ color: textColor, backgroundColor }}
        id={`weglot-language-${language}`}>
        {with_flags && <Flag language={language} flagType={flag_type} />}
        {displayName}
      </a>
    </div>
  );
};

export default Option;

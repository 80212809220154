import options from "../options/options";

export default function getInternalLanguageCode(code) {
  if (!code || !code.toLowerCase) {
    return;
  }
  const lowerCode = code.toLowerCase();
  const language = options.languages.find(({ language_to, custom_code }) => {
    if (language_to === lowerCode) {
      return true;
    }
    if (custom_code) {
      return custom_code.toLowerCase() === lowerCode;
    }
  });
  return language ? language.language_to : code;
}

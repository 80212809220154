var hooks = {};

export var registerHook = (name, value) => {
  if (!hooks[name]) {
    hooks[name] = [];
  }
  hooks[name].push(value);
};

export var loadHook = (name, arg) => {
  if (hooks[name]) {
    for (const h of hooks[name]) {
      h(arg);
    }
  }
};

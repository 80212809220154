import { applyOptions, getSettings } from "./shared/options";
import { addStyleTag, getSwitcherPosition } from "./shared/utils";
import logger from "./shared/logger";
import { editorHostnames } from "common/helpers/editors";
// @ts-ignore
import { createSwitcher } from "./templates/SWITCHER_NAME/Switcher"; // Replaced by rollup

const optSwitchers = []; // Switcher options with this template name
const langSwitchers = []; // Rendered switcher DOM elements with this template name
const commonEditorHost = editorHostnames.SWITCHER.split(".")
  .slice(0, -1)
  .join(".");
const inEditor = window.location.hostname.indexOf(`${commonEditorHost}.`) > -1;
// @ts-ignore
const isDefaultTemplate = "SWITCHER_NAME" === "default";

// Called for all switchers from Switcher Editor and WordPress.
// Called for non-default switchers from library. The library adds the default switcher,
// except when it is in the Switcher Editor context.
function initSwitchers() {
  const settings = getSettings();
  if (!settings) {
    logger.error("Couldn't find settings for this project.", {
      sendToDatadog: false,
    });
    return;
  }
  applyOptions(settings); // Make options available to Lib-JS functions.

  window["Weglot"].switchers = {
    ...(window["Weglot"].switchers || {}),
    SWITCHER_NAME: {
      addSwitchers,
      removeSwitchers,
      addInteractiveSwitcher,
      removeInteractiveSwitcher,
    },
  };
  addStyleTag(`COMMON_SWITCHER_STYLE`, "common");

  if (!settings.switchers) {
    // Should have been added by library or for WordPress in getSettings. Exit and log to DD.
    logger.error("Unable to add Weglot language switcher into page.", {
      sendToDatadog: false,
    });
    return;
  }

  settings.switchers
    .filter(
      s =>
        (s.template && s.template.name === "SWITCHER_NAME") ||
        (!s.template && isDefaultTemplate)
    )
    .forEach(s => {
      optSwitchers.push(s);
    });
  addSwitchers();
}

function markAsAdded(switcher) {
  switcher.ready = true;
  // Notify other template scripts that a switcher was added here.
  window["Weglot"].switchers["SWITCHER_NAME"].ready = true;
}

function removeInteractiveSwitcher({ target = null, sibling = null }) {
  const switcherIndex = optSwitchers.findIndex(s => {
    const { location } = s;
    if (!location) {
      return !target && !sibling;
    }
    const siblingsEqual =
      (!sibling && !location.sibling) || sibling === location.sibling;
    const targetsEqual =
      (!target && !location.target) || target === location.target;
    return siblingsEqual && targetsEqual;
  });
  if (switcherIndex === 0 || switcherIndex) {
    optSwitchers.splice(switcherIndex, 1);
    langSwitchers.splice(switcherIndex, 1);
  }
}

function addSwitchers(node = document.documentElement) {
  optSwitchers.forEach(s => addInteractiveSwitcher(s, node));
  const weglot = window["Weglot"];
  const switchers = weglot.switchers || {};
  if (inEditor) {
    // In Switcher Editor, no need to add default switcher, even if all other
    // switchers were invalid.
    return;
  }

  if (Object.keys(switchers).some(key => switchers[key].ready)) {
    // Another template script already added a switcher, so don't add
    // the default switcher.
    return;
  }

  // Special case: Default switcher added by library, not external template.
  // Check if a switchers is ready in Weglot.options.
  if (
    weglot.options &&
    weglot.options.switchers &&
    weglot.options.switchers.some(s => s.ready)
  ) {
    return;
  }
  addInteractiveSwitcher({ location: {} });
}

function removeSwitchers() {
  langSwitchers.forEach(
    langSwitcher =>
      langSwitcher.parentNode &&
      langSwitcher.parentNode.removeChild(langSwitcher)
  );
  langSwitchers.splice(0);
  optSwitchers.map(s => (s.ready = false));
}

// Returns the rendered switcher element or null if an error occurred.
function addInteractiveSwitcher(switcher, node = document.documentElement) {
  if (!switcher || switcher.ready) {
    return;
  }
  const { location = {} } = switcher;

  const { error, targetNode, siblingNode, defaultPosition } =
    getSwitcherPosition(location, node);

  if (error) {
    if (inEditor) {
      window["Weglot"].switchers.onFail(switcher);
    } else {
      logger.warn(error, { sendToDatadog: false });
    }
    return;
  }

  const langSwitcher = createSwitcher(switcher);
  langSwitcher["weglotSwitcher"] = switcher;
  langSwitchers.push(langSwitcher);

  if (defaultPosition) {
    langSwitcher.classList.add("wg-default");
    document.body.appendChild(langSwitcher);
    markAsAdded(switcher);
    return langSwitcher;
  }

  targetNode.insertBefore(langSwitcher, siblingNode);
  markAsAdded(switcher);
  return langSwitcher;
}

function init() {
  try {
    initSwitchers();
  } catch (e) {
    logger.error(e);
  }
}

if (document.readyState !== "loading") {
  init();
} else {
  document.addEventListener("DOMContentLoaded", () => {
    init();
  });
}

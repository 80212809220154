import { editorHostnamesValues } from "common/helpers/editors";

/* GENERIC JS HELPERS */
import { loadHook } from "../utils/hooks";
import { registerEvent, triggerEvent } from "../utils/events";
import { setStoredLang, removeStoredLang } from "./helpers/storedLang";
import getCurrentLanguage, {
  setCurrentLanguage,
} from "./helpers/getCurrentLanguage";
import getAvailableLanguagesList from "./helpers/getAvailableLanguages";
import getLanguageURL from "./helpers/getLanguageURL";
import isExcludedPath from "./helpers/isExcludedPath";

/* WEGLOT CORE FUNCTIONS */
import { translate } from "./translator";
import { getPayload, setWords, parseNodes, addNodes } from "./nodes/nodes";
import {
  translateNewNodes,
  listenDynamic,
  disconnectDynamic,
} from "./nodes/dynamic";

/* SWITCHER */
import addSwitcher from "./switcher/addSwitcher";
import { storeAutoSwitchLanguage } from "./helpers/autoSwitch";

/* UI */
import { removeStyleForTransition } from "./ui/transition";
import { createProgressBar, removeProgressBar } from "./ui/progressBar";

import options from "./options/options";
import { updateSearchForms } from "./addons/updateSearchForms";
import addExtensionListener from "./addons/extensionEvents";
import xhooks from "./addons/xhooks";
import listenFrames from "./addons/translateFrame";
import { inFrame, safeQuerySelectorAll } from "../utils/helpers";
import updateEventInputs from "./addons/updateEventInputs";
import { previewDomain } from "./constants";
import logger from "../utils/logger";
import { removeUnusedLinkHooks } from "./setLinkHooks";
import translateShadowRoots from "./addons/translateShadowRoots";

registerEvent(
  "initialized",
  () => {
    if (options.translate_search && !options.switcher_editor) {
      updateSearchForms();
    }
  },
  true
);

export function start(langTo, store, initialTranslation) {
  if (!initialTranslation && langTo && window.top === window) {
    // If we don't translate the page and we are not in an iframe, check redirect
    if (checkRedirectTo(langTo)) {
      return;
    }
  }

  let nodes = [];
  try {
    nodes = parseNodes();
    addNodes(nodes);
  } catch (e) {
    logger.warn(e);
  }

  const excluded = isExcludedPath();
  // Store the new language if it exists and we need to
  if (langTo && store && !excluded) {
    setStoredLang(langTo);
  }

  // Translate the page and set the switcher accordingly

  // Turn off dynamics if on excluded path or original language
  if (
    options.is_connect &&
    !options.dynamicPushState &&
    (excluded || langTo === options.language_from)
  ) {
    disconnectDynamic();
  } else {
    listenDynamic();
  }
  // Apply xhooks request overrides (proxify and search)
  xhooks();
  // Listen for messages from the Visual Editor extension
  addExtensionListener();

  if (initialTranslation || excluded) {
    setPageLanguage(langTo);
  } else {
    if (options.is_connect && !excluded) {
      loadHook("onConnectPageLoad", langTo);
    }
    if (options.force_translation) {
      const toTranslate = [];
      for (let node of nodes) {
        if (
          (node.closest && node.closest(options.force_translation)) ||
          (!node.closest &&
            node.parentNode &&
            node.parentNode.closest &&
            node.parentNode.closest(options.force_translation))
        ) {
          toTranslate.push(node);
        }
      }
      translateNewNodes(toTranslate);
    }
  }

  // Add the switcher
  if (
    !excluded ||
    excluded.language_button_displayed ||
    !excluded.allExcluded
  ) {
    addSwitcher();
  }

  // Don't translate dynamic
  if (excluded) {
    triggerEvent("initialized", langTo);
    return;
  }

  if (options.remove_unused_link_hooks) {
    removeUnusedLinkHooks();
  }

  // Translate shadow DOMs
  translateShadowRoots(
    safeQuerySelectorAll(
      document,
      options.dynamics.map(({ value }) => value).join(",")
    )
  );
  // Update and listen inputs
  updateEventInputs();
  // Listen for frames, send language to them
  listenFrames();
  // Translation native dialogs
  translateDialogs();
  triggerEvent("initialized", langTo);
}

export function switchTo(targetLang) {
  const previousLanguage = getCurrentLanguage();
  if (targetLang === previousLanguage) {
    return;
  }

  if (options.visual_editor) {
    // SwitchTo called by custom switcher / Squarespace in connect; prevent
    // language change and pass the new URL to VE.
    getLanguageURL(targetLang, targetUrl => {
      if (targetUrl === "#") {
        return setPageLanguage(targetLang, previousLanguage);
      }

      window.dispatchEvent(
        new CustomEvent("veLanguageChangeUrl", {
          detail: { targetUrl },
        })
      );
    });

    return;
  }

  setPageLanguage(targetLang, previousLanguage);
}

export function setPageLanguage(targetLang, previousLanguage) {
  if (!getAvailableLanguagesList().includes(targetLang)) {
    removeStyleForTransition();
    logger.warn(`${targetLang} isn't a language you have added`, {
      sendToDatadog: false,
    });
    return;
  }

  // We know this is an available language, so store autoswitch
  // information if this is enabled.
  if (options.auto_switch || options.geo_auto_switch) {
    storeAutoSwitchLanguage(targetLang);
  }

  const excluded = isExcludedPath();
  // We store the current language on Javascript integration
  if (!options.is_connect && !excluded) {
    setStoredLang(targetLang);
  }

  // Connect: redirect
  if (checkRedirectTo(targetLang)) {
    return;
  }

  // JS: update dynamically words
  if (options.loading_bar) {
    createProgressBar();
  }
  setCurrentLanguage(targetLang);
  if (isExcludedPath()) {
    removeStyleForTransition();
    removeProgressBar();
    return;
  }

  if (targetLang === options.language_from) {
    loadHook("onPageLanguageSet", targetLang);
    removeStyleForTransition();
    setWords(null, targetLang);
    if (options.loading_bar) {
      removeProgressBar();
    }
    document.documentElement.setAttribute("lang", targetLang);
    triggerEvent("languageChanged", targetLang, previousLanguage || "");
    return;
  }

  // Get payload: { words, types } to translate
  const payload = getPayload();
  translate(payload, targetLang)
    .then(translated => {
      removeStyleForTransition();
      setWords(translated, targetLang);
      document.documentElement.setAttribute("lang", targetLang);
      triggerEvent("languageChanged", targetLang, previousLanguage || "");
      if (options.loading_bar) {
        removeProgressBar();
      }
    })
    .catch(e => {
      if (options.loading_bar) {
        removeProgressBar();
      }
      removeStyleForTransition();
      removeStoredLang();
      throw e;
    });
  loadHook("onPageLanguageSet", targetLang);
}

function checkRedirectTo(targetLang) {
  if (
    (!options.is_connect && options.technology_name !== "WordPress") ||
    getCurrentLanguage() === targetLang
  ) {
    return false;
  }
  if (
    options.host &&
    !(
      options.previewHash && window.location.hostname.includes(previewDomain)
    ) &&
    !getAllowedHosts().includes(window.location.hostname)
  ) {
    if (!inFrame()) {
      // If in iFrame, likely just to external site, no need to log to user.
      logger.warn(
        `"${window.location.hostname}" is not configured with Weglot. Please contact support@weglot.com`,
        { sendToDatadog: false }
      );
    }
    return false;
  }
  getLanguageURL(targetLang, url => window.location.replace(url));
  return true;
}

function translateDialogs() {
  // TODO: add prompt and confirm
  ["alert"].forEach(dialog => {
    const func = window[dialog];
    window[dialog] = function () {
      if (typeof arguments[0] !== "string") {
        return;
      }
      const currentLang = getCurrentLanguage();
      if (options.language_from === currentLang) {
        return func.apply(window, arguments);
      }
      const payload = [{ t: 2, w: arguments[0] }];
      return translate(payload, currentLang, { title: false, cdn: true }).then(
        translated => {
          arguments[0] = translated.to_words[0];
          return func.apply(window, arguments);
        }
      );
    };
  });
}

function getAllowedHosts() {
  if (options.subdirectory) {
    return [options.host, ...editorHostnamesValues];
  }
  return options.languages
    .map(l => l.connect_host_destination && l.connect_host_destination.host)
    .concat([options.host, ...editorHostnamesValues]);
}

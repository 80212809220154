import { isEditorHost } from "common/helpers/editors";
import { getInjectedWeglotData } from "common/helpers/utils";

import options from "./options";
import logger from "./logger";

// Check and stock all results of each function
function weakMapProxy(weakMap, node, test) {
  const found = node && node[weakMap];
  if (found) {
    if (found.textContent === node.textContent) {
      return found.result;
    }
  }
  const result = test(node);
  if (!node) {
    return result;
  }
  node[weakMap] = { result, textContent: node.textContent };
  return result;
}

export function isValidMergeNode(node) {
  return weakMapProxy("__validMergeNodes", node, node => {
    return (
      node &&
      onlyInlineChilds(node) &&
      isValidTextNode(node) &&
      !containsNoTranslate(node)
    );
  });
}

export function isValidTextNode(node, trim = true) {
  return weakMapProxy("__validTextNodes", node, node => {
    return !!(
      node.textContent &&
      (!trim || node.textContent.trim()) &&
      node.textContent.indexOf("BESbswy") === -1 &&
      (!node.parentNode ||
        !node.parentNode.nodeName ||
        ["script", "style", "noscript", "textarea"].indexOf(
          node.parentNode.nodeName.toLowerCase()
        ) === -1) &&
      !isJSON(node.textContent)
    );
  });
}

export function canMergeNode(node) {
  try {
    if (
      options.mergedSelectorRemove &&
      safeClosest(node, options.mergedSelectorRemove)
    ) {
      return false;
    }
  } catch (_) {} // eslint-disable-line

  if (options.mergeNodes && options.mergeNodes.indexOf(node.nodeName) !== -1) {
    return true;
  }

  return (
    (node.dataset && node.dataset.wgMerge !== undefined) ||
    (options.selectorMerging &&
      node.matches &&
      node.matches(options.selectorMerging))
  );
}

export function onlyInlineChilds(node) {
  return weakMapProxy("__onlyInlineChildsNodes", node, node => {
    if (!node.childNodes) {
      return true;
    }
    for (const child of node.childNodes) {
      if (child.weglot || !canMergeNode(child) || !onlyInlineChilds(child)) {
        return false;
      }
    }
    return true;
  });
}

function deepCheckNoTranslate(node) {
  if (!node.children) {
    return false;
  }

  for (const child of node.children) {
    if (
      ("wgNoTranslate" in child && child.wgNoTranslate) ||
      deepCheckNoTranslate(child)
    ) {
      return true;
    }
  }

  return false;
}

export function containsNoTranslate(node) {
  return weakMapProxy("__containsNoTranslateNodes", node, node => {
    if (node.nodeType !== 1) {
      return false;
    }
    return (
      !!node.querySelector(`[${options.noTranslateAttribute}]`) ||
      deepCheckNoTranslate(node)
    );
  });
}

function hasNoTranslateParent(element) {
  if (!element) {
    return false;
  }

  if ("wgNoTranslate" in element && element.wgNoTranslate) {
    return true;
  }

  return hasNoTranslateParent(element.parentNode);
}

// Checks if the element is a child of [data-wg-notranslate]
// or checks recursively all its ancestors for the wgNoTranslate property (isNoTranslateRec)
export function isNoTranslate(element) {
  if (!element) {
    return false;
  }

  // Apply closest on element or its parent (e.g. if it is a text node and doesn't have closest)
  const node = element.closest ? element : element.parentNode;
  if (node && safeClosest(node, `[${options.noTranslateAttribute}]`)) {
    return true;
  }

  return hasNoTranslateParent(element);
}

export function isJSON(string) {
  string = string.trim();
  if (!string) return false;
  const firstChar = string.charAt(0);
  if (firstChar !== "[" && firstChar !== "{") return false;
  const lastChar = string[string.length - 1];
  if (lastChar !== "]" && lastChar !== "}") return false;
  string = string
    .replace(/\\(?:["\\/bfnrt]|u[0-9a-fA-F]{4})/g, "@")
    .replace(
      /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g,
      "]"
    )
    .replace(/(?:^|:|,)(?:\s*\[)+/g, "");
  return /^[\],:{}\s]*$/.test(string);
}

export function getAttributes(el) {
  const attributes = [];
  for (const attribute of el.attributes) {
    attributes.push(attribute);
  }
  return attributes;
}

export function removeAttributes(el) {
  if (!el.attributes) return el;
  while (el.attributes.length > 0) el.removeAttribute(el.attributes[0].name);
  return el;
}

export function parentTag(node) {
  return node.parentNode && node.parentNode.nodeName.toUpperCase();
}

export function getElementsFromSelectors(node, array) {
  const elements = [];
  const selector = array.join(",");

  if (node.matches && node.matches(selector)) {
    elements.push(node);
  }

  if (node.childElementCount > 0) {
    elements.push(...node.querySelectorAll(selector));
  }

  return elements;
}

const tryCatch = (property, errorReturn) => (element, q) => {
  try {
    // @TODO: is this escape still useful ?
    let escaped = q;
    if (escaped.indexOf(":") !== -1) {
      escaped = escaped.replace(/([^\\]):/g, "$1\\:");
    }
    return element[property] ? element[property](escaped) : errorReturn;
  } catch (e) {
    // Try again without escape
    try {
      return element[property] ? element[property](q) : errorReturn;
    } catch (e) {
      logger.warn(e, {
        consoleOverride: `Your CSS rules are incorrect: ${q}`,
        sendToDatadog: false,
      });
    }
  }
  return errorReturn;
};

export const safeQuerySelectorAll = tryCatch("querySelectorAll", []);
export const safeMatches = tryCatch("matches", false);
export const safeClosest = tryCatch("closest", null);

export function getCurrentHostname() {
  const { hostname, search } = window.location;
  if (!isEditorHost(hostname) || !search) {
    return hostname;
  }

  const weglotData = getInjectedWeglotData();
  if (weglotData && weglotData.editorCurrentURL) {
    const url = new URL(weglotData.editorCurrentURL);
    return url.hostname;
  }

  const match = decodeURIComponent(search).match(/url=https?:\/\/([^/]+)/);
  if (match) {
    return match[1];
  }
  logger.warn("[Weglot] Unable to get current hostname");
  return hostname;
}

export function hasHTML(string) {
  return string.indexOf("<") > -1 && string.indexOf(">") > -1;
}

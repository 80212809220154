import { isEditorHost } from "common/helpers/editors";
import { getInjectedWeglotData } from "common/helpers/utils";
import URL from "common/helpers/url";

import { registerEventOr, triggerEvent } from "../../utils/events";
import options from "../options/options";
import logger from "../../utils/logger";

function getCurrentLocation() {
  if (!isEditorHost(window.location.hostname) || !document.baseURI) {
    const { href, hostname, pathname, search } = window.location;
    return { url: href, hostname, pathname, search };
  }

  const weglotData = getInjectedWeglotData();
  if (weglotData && weglotData.editorCurrentURL) {
    const { hostname, pathname, search } = new URL(weglotData.editorCurrentURL);
    return { url: document.baseURI, hostname, pathname, search };
  }

  logger.error(`Problem finding editor page location`, {
    sendToConsole: false,
  });

  const { hostname, pathname, search } = new URL(document.baseURI);
  return { url: document.baseURI, hostname, pathname, search };
}

registerEventOr(
  options && Object.keys(options).length > 0,
  "onOptionsReady",
  () => {
    if (options.dynamicPushState) {
      // Wrapping pushState function to detect url change
      const legacyPushState = history.pushState;
      history.pushState = (...params) => {
        legacyPushState.apply(history, params);

        triggerEvent("onCurrentLocationChanged");
      };
    }
  }
);

export default getCurrentLocation;

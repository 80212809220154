import options from "../options/options";
import { isSearchBot } from "../../utils/helpers";
import getBestAvailableLanguage from "./getBestAvailableLanguage";
import isExcludedPath from "./isExcludedPath";
import getCurrentLanguage from "./getCurrentLanguage";
import storage from "../../utils/storage";

export function getAutoSwitchLanguage() {
  if (window.location.search.indexOf("no_redirect=true") > -1) {
    // TLD returning to original lang: Store autoswitch info.
    storeAutoSwitchLanguage(getCurrentLanguage());
    return;
  }
  if (
    !options.auto_switch ||
    (options.subdirectory && options.injectedData) || // In subdirectory, autoswitch is managed in connect (because we have control over original site)
    storage({ type: "cookie" }).getItem("WG_CHOOSE_ORIGINAL") ||
    isSearchBot() ||
    options.visual_editor // No autoswitch on Visual Editor.
  ) {
    return;
  }

  const bestAvailableLang = getBestAvailableLanguage();
  if (bestAvailableLang && !isExcludedPath(bestAvailableLang)) {
    return bestAvailableLang;
  }
  if (
    options.auto_switch_fallback &&
    !isExcludedPath(options.auto_switch_fallback)
  ) {
    return options.auto_switch_fallback;
  }
}

export function storeAutoSwitchLanguage(destinationLanguage) {
  if (destinationLanguage === options.language_from) {
    const date = new Date();
    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
    storage({ type: "cookie" }).setItem("WG_CHOOSE_ORIGINAL", "1", {
      expires: date.toUTCString(),
    });
  } else {
    storage({ type: "cookie" }).removeItem("WG_CHOOSE_ORIGINAL");
  }
}

import { removeElement } from "common/helpers/utils";

import options from "./options/options";
import { registerEvent } from "../utils/events";
import getCurrentLanguage from "./helpers/getCurrentLanguage";
import getAvailableLanguages from "./helpers/getAvailableLanguages";
import { noTranslateAttribute } from "./constants";
import { switchTo } from "./core";
import { safeQuerySelectorAll } from "../utils/helpers";
import getLanguageURL from "./helpers/getLanguageURL";

const detectedLinks = [];

function hookSelector(l) {
  const buildAdditionalSelectors =
    options.linkHooksConfig && options.linkHooksConfig.buildAdditionalSelectors;

  return [
    `a[href='#Weglot-${l}']`,
    `a[href*='change-language.weglot.com/${l}']`,
    ...(buildAdditionalSelectors ? buildAdditionalSelectors(l) : []),
  ].join(",");
}

/**
 * Search for link hooks to add custom switcher
 *
 * @returns {boolean} Hooks found, switcher was added
 */
export default function setLinkHooks(node = document.body) {
  const additionalCheckSelectors =
    (options.linkHooksConfig &&
      options.linkHooksConfig.additionalCheckSelectors) ||
    [];

  if (
    safeQuerySelectorAll(
      node,
      [
        'a[href^="#Weglot-"]',
        'a[href*="change-language.weglot.com/"]',
        ...additionalCheckSelectors,
      ].join(",")
    ).length === 0
  ) {
    return;
  }

  let addedSwitcher = false;
  for (const language of getAvailableLanguages()) {
    const links = safeQuerySelectorAll(node, hookSelector(language));
    if (links.length === 0) {
      continue;
    }
    addedSwitcher = true;
    for (const link of links) {
      addLinkHook(language, link);
    }
  }

  registerEvent(
    "languageChanged",
    newLang => updateLinksLanguage(newLang),
    true
  );

  registerEvent("initialized", () => {
    const newLang = getCurrentLanguage();
    if (newLang === options.language_from) {
      return;
    }
    updateLinksLanguage(newLang);
  });
  return addedSwitcher;
}

function updateLinksLanguage(newLang) {
  detectedLinks.forEach(({ language, links }) => {
    const isActive = language === newLang;
    const classAction = isActive ? "add" : "remove";
    const configAction = isActive ? "onLinkActive" : "offLinkActive";
    links.forEach(link => {
      link.classList[classAction]("weglot-link--active");
      if (options.linkHooksConfig && options.linkHooksConfig[configAction]) {
        options.linkHooksConfig[configAction](link);
      }
    });
  });
}

export function addLinkHook(language, link) {
  let linkHookData = detectedLinks.find(dl => dl.language === language);
  if (linkHookData && linkHookData.links.indexOf(link) !== -1) {
    // Link hook already exists, skip
    return;
  }

  if (!linkHookData) {
    linkHookData = {
      language,
      links: [],
      onLinkClick: e => {
        e.preventDefault();
        switchTo(language);
      },
    };
    detectedLinks.push(linkHookData);
  }

  link.setAttribute(noTranslateAttribute, "");
  link.classList.add("weglot-link", `weglot-link-${language}`);
  if (language === getCurrentLanguage()) {
    link.classList.add("weglot-link--active");
    if (options.linkHooksConfig && options.linkHooksConfig.onLinkActive) {
      options.linkHooksConfig.onLinkActive(link);
    }
  }
  getLanguageURL(language, url => link.setAttribute("href", url));
  link.addEventListener("click", linkHookData.onLinkClick);

  linkHookData.links.push(link);
}

export function removeLinkHook(language, link) {
  const linkHookData = detectedLinks.find(dl => dl.language === language);
  if (!linkHookData) {
    return;
  }

  const linkIndex = linkHookData.links.indexOf(link);
  if (linkIndex === -1) {
    return;
  }

  linkHookData.links.splice(linkIndex, 1);
  link.removeEventListener("click", linkHookData.onLinkClick);
}

export function removeUnusedLinkHooks() {
  const languages = getAvailableLanguages();
  const unusedLanguages = options.languages
    .map(l => l.custom_code || l.language_to)
    .filter(language => !languages.includes(language));

  // if there is only one language available, it's the original language
  if (languages.length === 1) {
    unusedLanguages.push(options.language_from);
  }

  const unusedLangSelector = unusedLanguages
    .map(language => hookSelector(language))
    .join(",");
  const switchers = safeQuerySelectorAll(document, unusedLangSelector);
  for (const selector of switchers) {
    removeElement(selector);
  }
}

import options from "./options/options";
import query from "./query/query";
import getCurrentLanguage from "./helpers/getCurrentLanguage";
import getInternalLanguageCode from "./helpers/getInternalLanguageCode";

/**
 * Translate list of @param words in the @param targetLanguage
 * @param {object[]} words
 * @param {string} targetLanguage
 * @returns {object}
 */
export function translate(words, targetLanguage, opts = {}) {
  const defaultOpts = { title: true, cdn: false, search: false };
  opts = Object.assign({}, defaultOpts, opts);
  const body = {
    l_from: options.language_from,
    l_to: targetLanguage,
    words,
  };
  if (opts.title) {
    body.title = document.title;
  }
  // @ts-ignore
  return query(body, opts);
}

/**
 * Public method to get a translation
 * -> Mainly used by ISP Shopify App.
 * @param {string} word
 * @param {(string) => *} callback
 * @returns {boolean}
 */
export function search(word, callback) {
  if (typeof word !== "string" || typeof callback !== "function") {
    return false;
  }
  const currentLang = getCurrentLanguage();
  if (currentLang === options.language_from) {
    callback(word);
    return false;
  }
  const body = {
    l_from: getInternalLanguageCode(currentLang),
    l_to: options.language_from,
    words: [{ t: 2, w: word }],
  };

  query(body, { cdn: true, search: true })
    .then(response => response.to_words[0].toLowerCase().trim())
    .then(callback);
  return true;
}

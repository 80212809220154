import migratedLanguages from "common/data/migratedLanguages.js";

function getFlag(code, languages) {
  const migratedLanguage = migratedLanguages.find(ml => ml.previous === code);
  if (migratedLanguage) {
    code = migratedLanguage.new;
  }
  return languages[code] ? languages[code].flag : "";
}

export default function commonGetLanguageFlag(code, options, languages) {
  if (!code || !code.toLowerCase) {
    return "";
  }
  if (options.language_from === code) {
    return options.language_from_custom_flag || getFlag(code, languages);
  }
  const lowerCode = code.toLowerCase();
  const language = options.languages.find(
    ({ language_to, custom_code }) =>
      language_to === lowerCode ||
      (custom_code && custom_code.toLowerCase() === lowerCode)
  );
  if (!language) {
    return "";
  }
  return language.custom_flag || getFlag(language.language_to, languages);
}

import { render, h } from "preact";

import ErrorBoundary from "editor-components/src/components/ErrorBoundary";
import { isEditorHost } from "common/helpers/editors";
import getLanguageURL from "library/src/weglot/helpers/getLanguageURL";

import options from "./options";
import { getDefaultSwitcherProps } from "../templates";
import logger from "./logger";

export function getCurrentLanguage() {
  if (
    options.technology_name === "WordPress" &&
    options.injectedData &&
    !options.is_connect
  ) {
    const currentLang = options.injectedData.current_language;
    const found = options.languages.find(l => l.language_to === currentLang);
    return (found && found.custom_code) || currentLang;
  }
  if (options.switcher_editor) {
    return options.language_from;
  }
  return window["Weglot"].getCurrentLang();
}

export function getColor(colorOpts, state) {
  const { active, hover } = state;
  const {
    bg_inactive,
    bg_active,
    bg_hover,
    text_inactive,
    text_active,
    text_hover,
  } = colorOpts;
  const textColor = active ? text_active : hover ? text_hover : text_inactive;
  const backgroundColor = active ? bg_active : hover ? bg_hover : bg_inactive;
  return { textColor, backgroundColor };
}

export function bound(a, b, c) {
  if (b < a) {
    return a;
  }
  if (b > c) {
    return c;
  }
  return b;
}

export function resize(initial, factor) {
  if (!factor || factor === 1) {
    return initial;
  }
  return Math.round(initial * factor * 100) / 100;
}

export function switchTo(targetLang) {
  if (
    options.technology_name === "WordPress" &&
    options.injectedData &&
    !options.is_connect
  ) {
    return getLanguageURL(targetLang, url => {
      if (isEditorHost(window.location.hostname)) {
        window.dispatchEvent(
          new CustomEvent("veLanguageChangeUrl", {
            detail: { targetUrl: url },
          })
        );
      } else {
        window.location.replace(url);
      }
    });
  }
  return window["Weglot"].switchTo(targetLang);
}

export const keycodes = {
  enter: 13,
  escape: 27,
  arrowUp: 38,
  arrowDown: 40,
};

// Returns weglot-container DOM element containing the rendered switcher of template [name].
// If no switcherOpts provided, uses the default values provided in templates metadata.
export function renderSwitcher(Switcher, name) {
  function createSwitcher(switcher) {
    const { style = {}, opts = {}, colors = {} } = switcher || {};
    const {
      style: defaultStyle,
      opts: defaultOpts,
      colors: defaultColors,
    } = getDefaultSwitcherProps(name);
    const container = document.createElement("div");
    render(
      <ErrorBoundary logger={logger.error} displayError={false}>
        <Switcher
          style={{
            ...defaultStyle,
            ...style,
          }}
          opts={{
            ...defaultOpts,
            ...opts,
          }}
          colors={{
            ...defaultColors,
            ...colors,
          }}
        />
      </ErrorBoundary>,
      container
    );
    container.classList.add("weglot-container");
    if (!window["Weglot"]) {
      window["Weglot"] = {};
    }
    if (!window["Weglot"].switcherId) {
      window["Weglot"].switcherId = 1;
    }
    const switcherId = String(window["Weglot"].switcherId++);
    container.setAttribute("data-switcher-id", switcherId);
    container.id = `weglot-switcher-${switcherId}`;
    return container;
  }
  return createSwitcher;
}

function camelToKebab(str) {
  return str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2").toLowerCase();
}

export function buildCSS(cssObject, componentName) {
  const customCss = options.button_style && options.button_style.custom_css;
  const isDefault = componentName === "default";
  const scriptStyle = cssObject
    .map(({ selector, declarations }) => {
      // Default switcher needs extra prefix to override welgot.min.css.
      const defaultPrefix = isDefault ? "aside.country-selector" : "";
      const templateClass = `.weglot_switcher.${componentName}`;
      return [
        `${defaultPrefix}${templateClass}${selector} {`,
        Object.keys(declarations)
          .map(
            property =>
              `\t${camelToKebab(property)}: ${declarations[property]};`
          )
          .join("\n"),
        "}",
      ].join("\n");
    })
    .join("\n\n");
  if (!customCss || options.switcher_gallery) {
    return scriptStyle;
  }
  return `${scriptStyle}\n\n${customCss}`;
}

export const FLAGS = ["none", "shiny", "square", "circle", "rectangle_mat"];

export function getRect(element) {
  if (!element) {
    return {
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
    };
  }
  return element.getBoundingClientRect();
}

export function addStyleTag(styles, switcherName) {
  if (!styles) {
    return;
  }
  const domElement = document.querySelector(
    `style#weglot-switcher-${switcherName}`
  );
  if (domElement) {
    domElement.textContent = styles;
    return;
  }
  if (!document.head) {
    return;
  }
  const styleElement = document.createElement("style");
  styleElement.id = `weglot-switcher-${switcherName}`;
  styleElement.textContent = styles;
  document.head.appendChild(styleElement);
}

export { default as getSwitcherPosition } from "library/src/weglot/switcher/getSwitcherPosition";
export { default as getLanguageName } from "library/src/weglot/helpers/getLanguageName";
export { default as getLanguageFlag } from "library/src/weglot/helpers/getLanguageFlag";
export { default as getAvailableLanguages } from "library/src/weglot/helpers/getAvailableLanguages";
export { safeQuerySelector } from "library/src/utils/helpers";

import { mergeNodesList } from "@weglot/translation-definitions/mergeNodesList";

import getTextNodes, { setNoTranslate } from "./getter";
import setTextNodes, {
  setAttributeTranslation,
  setHTMLTranslation,
} from "./setter";
import { isNoTranslate } from "./helpers";
import { getDefinitions } from "./definition";
import options from "./options";

/** @type {{type, selectors, attribute: {get,set,name}}[]} */
let definitions = [];

/**
 * Init module with document and options
 *
 * @param {*} document
 * @param {*} externOptions
 */
function htmlParser(document, externOptions) {
  if (!externOptions || !externOptions.translation_engine) {
    throw "translation_engine is required";
  }
  Object.assign(options, externOptions);
  options.document = document;
  options.mergeNodes = getMergeNodesList(externOptions.translation_engine);
  if (Array.isArray(options.extra_merged_selectors)) {
    options.selectorMerging = externOptions.extra_merged_selectors
      .filter(str => str && typeof str === "string")
      .join(",");
  }
  if (externOptions.merged_selectors_remove) {
    options.mergedSelectorRemove = externOptions.merged_selectors_remove
      .map(({ value }) => value)
      .join(",");
  }
  definitions = getDefinitions(options);
  return { getTextNodes, setTextNodes, definitions };
}

function getMergeNodesList(translation_engine) {
  mergeNodesList.v2.unshift("#text", "#comment");
  return Object.keys(mergeNodesList).reduce((acc, key, version) => {
    if (translation_engine >= version + 1) {
      [].push.apply(acc, mergeNodesList[key]);
    }
    return acc;
  }, []);
}

export {
  getTextNodes,
  setTextNodes,
  definitions,
  htmlParser,
  setAttributeTranslation,
  setHTMLTranslation,
  isNoTranslate,
  setNoTranslate,
};

export const settingsUrl = "SETTINGS_ENDPOINT";

export const previewDomain = "PREVIEW_DOMAIN";

export const langStorageKey = "wglang";

export const transitionStyleId = "wg-style-trans";

export const noTranslateAttribute = "data-wg-notranslate";

export const sourceApiHost = "SOURCE_API_HOST";

export const proxyDedicatedHost = "PROXY_DEDICATED_HOST";

export const proxyInternalDir = "wg-cgi";

export const proxyFormats = {
  internal: "proxy_internal",
  dedicated: "proxy_dedicated",
};

// @ts-ignore no-undef
export const isLocalDev = LOCAL_DEV; //eslint-disable-line

export const maxCFBytes = 8100;

export const cacheKey = "wg-translations";
export const cacheSlugs = "wg-slugs";

export const maxApiRequestSecond = 10;

export const technologies = {
  wordpress: { name: "WordPress", id: 1 },
  shopify: { name: "Shopify", id: 2 },
  bigcommerce: { name: "BigCommerce", id: 3 },
  jimdo: { name: "Jimdo", id: 4 },
  squarespace: { name: "Squarespace", id: 5 },
  wix: { name: "Wix", id: 6 },
  weebly: { name: "Weebly", id: 9 },
  drupal: { name: "Drupal", id: 10 },
  other: { name: "Other", id: 12 },
  webflow: { name: "Webflow", id: 13 },
  prestashop: { name: "Prestashop", id: 14 },
  magento: { name: "Magento", id: 15 },
  squareonline: { name: "Square Online", id: 16 },
  bubble: { name: "Bubble", id: 17 },
  salesforce: { name: "Salesforce", id: 18 },
};

export const listOptions = [
  "excluded_blocks",
  "excluded_blocks_remove",
  "dynamics",
  "excluded_paths",
  "dangerously_force_dynamic",
  "extra_definitions",
  "translate_event",
  "whitelist",
];

export const availableEvents = [
  "polyfillReady",
  "languageChanged",
  "initialized",
  "start",
  "switchersReady",
];

export const DEFAULT_OPTIONS = {
  button_style: {
    full_name: true,
    with_name: true,
    is_dropdown: true,
    with_flags: false,
    flag_type: "",
  },
  switchers: [],
  auto_switch: false,
  auto_switch_fallback: "",
  excluded_blocks: [],
  excluded_blocks_remove: [],
  whitelist: [],
  translate_event: [
    {
      selector: "[data-wg-translate-event]",
      eventName: null,
    },
  ],
  customer_tag: false,
  order_tag: true,
  dynamics: [],
  excluded_paths: [],
  wait_transition: true,
  hide_switcher: false,
  translate_search: false,
  media_enabled: false,
  search_forms: "",
  cache: false,
  live: true,
  loading_bar: true,
  search_parameter: "",
  translation_engine: 2,
  override_hreflang: true,
};

export const FLAGS = ["none", "shiny", "square", "circle", "rectangle_mat"];

import options from "../options/options";
import getInternalLanguageCode from "../helpers/getInternalLanguageCode";
import { queryCache, cacheSet, validateCache } from "./translationCache";
import getFormattedCurrentUrl from "./getFormattedCurrentUrl";
import request from "./request";

/**
 * Check the cache, prepare th request to Weglot API with @param body content,
 * update cache and return data
 * @param {object} body
 * @returns {Promise<object>}
 */
export default function query(body, opts = { search: false }) {
  const { l_to: targetLang, words } = body;
  const ignoreCache = options.visual_editor || opts.search;

  // Validate storage and clear any invalid data
  const cacheValid = !ignoreCache && validateCache();

  if (!words || !words.length) {
    // No words to translate: Don't fetch even if cache invalid
    return Promise.resolve({ to_words: [], from_words: [] });
  }

  const cacheResponse = cacheValid && queryCache(words, targetLang);
  if (cacheResponse && cacheResponse.isComplete) {
    // All words are already in cache and are valid.
    return Promise.resolve(cacheResponse.words);
  }

  // We fetch things we need from Weglot
  return request({
    ...body,
    request_url: getFormattedCurrentUrl(),
    l_to: getInternalLanguageCode(targetLang),
  }).then(translated => {
    if (ignoreCache) {
      // Visual Editor/ search: Don't save translations in storage
      return translated;
    }
    words.forEach((payload, index) => {
      const translation = translated.to_words[index];
      cacheSet(payload.w, payload.t, translation, targetLang);
    });
    return translated;
  });
}

import logger from "./logger";
import { availableEvents } from "../weglot/constants";

var registeredEvents = [];

export function publicRegisterEvent(name, callback) {
  return registerEvent(name, callback, false);
}

export function registerEventOr(condition, name, callback) {
  if (condition) {
    return callback();
  }

  registerEvent(name, callback, true);
}

export function registerEvent(name, callback, internal) {
  if (typeof callback !== "function") {
    logger.error("You should provide a callback function as second argument", {
      sendToDatadog: internal, // Only log errors arising from internal callbacks.
    });
    return false;
  }

  // Using indexOf because it's used before polyfill are loaded
  if (!internal && availableEvents.indexOf(name) < 0) {
    logger.error(`No Weglot event is named ${name}`, { sendToDatadog: false });
    return false;
  }

  registeredEvents.push({
    name,
    callback,
    internal,
  });

  return true;
}

export function unregisterEvent(name, callback) {
  var unregistered = false;
  // We only unregister events that are not internal
  var mainCondition = i =>
    registeredEvents[i].name === name && !registeredEvents[i].internal;
  // If the second argument (optional) is set, we can remove a specific callback
  var condition;
  if (typeof callback === "function") {
    condition = i =>
      mainCondition(i) && registeredEvents[i].callback === callback;
  } else {
    condition = i => mainCondition(i);
  }
  for (var j = registeredEvents.length - 1; j >= 0; j--) {
    if (condition(j)) {
      registeredEvents.splice(j, 1);
      unregistered = true;
    }
  }
  return unregistered;
}

export function triggerEvent(name, ...args) {
  var eligibleEvents = registeredEvents.filter(event => event.name === name);
  for (var eventToCall of eligibleEvents) {
    try {
      eventToCall.callback(...args);
    } catch (e) {
      if (eventToCall.internal) {
        // If caused by internal callback, throw error.
        throw e;
      }
      logger.error(`Error triggering callback function: ${e}`, {
        sendToDatadog: false,
      });
    }
  }
}

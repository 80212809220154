export const editorHostnames = (() => {
  try {
    return JSON.parse('EDITOR_HOSTS'); // eslint-disable-line
  } catch (_) {
    // For unit tests, EDITOR_HOSTS is not replaced and fails the JSON.parse()
    return {};
  }
})();

export const editorHostnamesValues = Object.keys(editorHostnames).map(
  k => editorHostnames[k]
);

export function isEditorHost(hostname) {
  for (const h of editorHostnamesValues) {
    if (hostname.indexOf(h) !== -1) {
      return true;
    }
  }

  return false;
}

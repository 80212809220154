import { createSwitcher } from "switcher-templates/src/templates/default/Switcher";
import { removeElement } from "common/helpers/utils";
import addSwitcherTemplateScript from "common/helpers/addTemplateScript";

import options from "../options/options";
import getAvailableLanguagesList from "../helpers/getAvailableLanguages";
import getSwitcherPosition from "./getSwitcherPosition";
import setLinkHooks from "../setLinkHooks";
import getCurrentLanguage from "../helpers/getCurrentLanguage";
import { registerEvent, triggerEvent } from "../../utils/events";
import logger from "../../utils/logger";

let alreadyAddedSwitcher, defaultSwitcher, defaultSwitcherTimeout;
const langSwitchers = [];

registerEvent(
  "onCurrentLocationChanged",
  () => {
    // Dynamic page change with pushState: reset switchers
    removeSwitchers();
    addSwitcher();
  },
  true
);

export function addInteractiveSwitcher(
  switcher,
  node = document.documentElement
) {
  if (!switcher || switcher.ready) {
    // Switcher was already added: Do nothing
    return;
  }
  const style = switcher.style || options.button_style;
  const { location = {} } = switcher;

  const { error, targetNode, siblingNode, defaultPosition } =
    getSwitcherPosition(location, node);

  if (error) {
    logger.warn(error, { sendToDatadog: false });
    // Problem with location selectors: Do nothing
    return;
  }

  const langSwitcher = createSwitcher({
    ...switcher,
    style,
  });
  langSwitcher["weglotSwitcher"] = switcher;
  langSwitchers.push(langSwitcher);

  if (defaultPosition) {
    langSwitcher.classList.add("wg-default");
    document.body.appendChild(langSwitcher);
    switcher.ready = true;
    return langSwitcher;
  }

  langSwitcher.setAttribute("data-switcher-style-opt", JSON.stringify(style));

  // Add switcher to target node, before sibling node if it exists.
  targetNode.insertBefore(langSwitcher, siblingNode);
  switcher.ready = true;

  // Remove any empty weglot containers still in the DOM.
  const emptyContainers = node.querySelectorAll(".weglot-container:empty");
  for (let emptyContainer of emptyContainers) {
    removeElement(emptyContainer);
  }

  return langSwitcher;
}

export function removeSwitchers() {
  langSwitchers.forEach(
    langSwitcher =>
      langSwitcher.parentNode &&
      langSwitcher.parentNode.removeChild(langSwitcher)
  );
  langSwitchers.splice(0);
  removeTemplateSwitchers();

  alreadyAddedSwitcher = null;
  defaultSwitcher = null;
  options.button_style.ready = false;
  options.switchers.map(switcher => (switcher.ready = false));
}

function removeTemplateSwitchers() {
  const switchers = window["Weglot"].switchers || {};
  for (const key of Object.keys(switchers)) {
    switchers[key].removeSwitchers();
  }
}

// Switcher added somewhere, remove default switcher if added
function addedSwitcher() {
  if (!alreadyAddedSwitcher) {
    triggerEvent("switchersReady", getCurrentLanguage());
  }
  alreadyAddedSwitcher = true;
  clearTimeout(defaultSwitcherTimeout);
  if (defaultSwitcher) {
    defaultSwitcher.parentNode.removeChild(defaultSwitcher);
  }
}

// Check and add switchers if needed
// This idempotent function is called at every dynamic element detected on page
export default function addSwitcher(target = document) {
  if (getAvailableLanguagesList().length < 2 || options.hide_switcher) {
    return;
  }
  if (options.switcher_editor) {
    // Handled by Switcher Editor
    return;
  }

  const node = target.isConnected ? target : document;

  // 1. Check link hooks
  // @ts-ignore
  if (setLinkHooks(node)) {
    addedSwitcher();
  }

  // 2. Check for weglot_here
  const positions = node.querySelectorAll(
    "#weglot_here:not(.weglot-container),.weglot_here:not(.weglot-container)"
  );
  if (positions.length) {
    for (const container of positions) {
      const switcherElement = createSwitcher({ style: options.button_style });
      switcherElement.classList.add("weglot_here");
      container.parentNode.insertBefore(switcherElement, container);
      removeElement(container);
    }
    addedSwitcher();
  }

  // 3. Add options switcher, but not the default one

  for (const switcher of options.switchers) {
    if (switcher.default) {
      continue;
    }
    const { template } = switcher;
    if (!template || template.name === "default") {
      // @ts-ignore
      if (addInteractiveSwitcher(switcher, node)) {
        addedSwitcher();
      }
      continue;
    }
    if (!template.name) {
      // Invalid, skip this switcher option.
      continue;
    }
    const newScript = addSwitcherTemplateScript(template, false);
    if (!newScript) {
      // Template already in page, call its methods on this node
      const templateFunctions =
        window["Weglot"].switchers && window["Weglot"].switchers[template.name];
      if (templateFunctions) {
        templateFunctions.addSwitchers(node);
      }
    }
    // The template script adds a switcher to default position if it cannot
    // place switcher, so we don't need to add the default switcher.
    addedSwitcher();
  }

  // 4. Nothing found, add default switcher one time only
  if (alreadyAddedSwitcher || defaultSwitcher) {
    return;
  }

  const defaultOptions = options.switchers.find(
    switcher => switcher.default
  ) || { style: options.button_style };
  defaultSwitcherTimeout = setTimeout(function () {
    defaultSwitcher = addInteractiveSwitcher(defaultOptions);
    triggerEvent("switchersReady", getCurrentLanguage());
  });
}

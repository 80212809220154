import { isNoTranslate, setNoTranslate } from "html-parser-engine";
import { editorHostnamesValues } from "common/helpers/editors";

import getCurrentLanguage from "../helpers/getCurrentLanguage";
import isExcludedPath from "../helpers/isExcludedPath";
import {
  isIframeAccessible,
  safeClosest,
  safeQuerySelectorAll,
} from "../../utils/helpers";
import { proxifyIframes } from "../addons/translateFrame";
import options from "../options/options";
import { addNodes, getPayload, parseNodes, setWords } from "./nodes";
import { translate } from "../translator";
import { maxApiRequestSecond, noTranslateAttribute } from "../constants";
import addSwitcher from "../switcher/addSwitcher";
import { loadHook } from "../../utils/hooks";
import updateEventInputs from "../addons/updateEventInputs";
import translateShadowRoots from "../addons/translateShadowRoots";
import logger from "../../utils/logger";
import getSlugs from "../query/slugs";
import { getURL } from "../helpers/getLanguageURL";
import setLinkHooks from "../setLinkHooks";

let exceptions = null,
  timerNewNodes,
  newNodes = [];
const excludedAttributes = [noTranslateAttribute, "class", "id"];
let mutationQueues = [];
const observers = [];

function settedClosest(node) {
  do {
    if (node.weglot && node.weglot.setted) {
      return node;
    }
    node = node.parentElement || node.parentNode;
  } while (node);
}

// Triggered when dynamic nodes added on page, translated or not.
function onNewNodes(target, addedNodes) {
  if (timerNewNodes) {
    clearTimeout(timerNewNodes);
  }
  for (const addedNode of addedNodes) {
    if (addedNode.nodeType === 1) {
      newNodes.push(addedNode);
    }
  }
  if (!newNodes.length) {
    return;
  }
  timerNewNodes = setTimeout(function () {
    addSwitcher(target);
    updateEventInputs();
    if (options.is_connect && !options.subdirectory) {
      replaceLinks(newNodes);
    }
    if (options.proxify_iframes && options.proxify_iframes.length) {
      newNodes.forEach(node => proxifyIframes({ node }));
    }
    translateShadowRoots(newNodes);
    loadHook("onDynamicDetected");
    newNodes = [];
  }, 100);
}

function dispatchMutations(mutations, observedDocument) {
  const { dynamics } = options;

  const isShadowRoot =
    observedDocument instanceof ShadowRoot || observedDocument.host;
  const isIframe = observedDocument !== document && !isShadowRoot;
  let dynamicCheck = isEligibleDynamic;
  if (isIframe) {
    dynamicCheck = () => true;
  } else if (!dynamics || dynamics.length === 0) {
    dynamicCheck = () => false;
  }

  try {
    if (isExcludedPath()) {
      return;
    }
    mutations = filterMutations(mutations, dynamicCheck);
    if (!dynamics || dynamics.length === 0) {
      return;
    }
    if (mutations.length) {
      try {
        parseMutation(mutations, dynamicCheck);
      } catch (e) {
        logger.warn(e);
      }
    }
  } catch (e) {
    logger.warn(e, {
      consoleOverride: "Error in MutationObserver",
    });
  }
}

let listening = false;
export function listenDynamic(listen = true) {
  const { excluded_blocks, is_connect } = options;

  listening = listen;
  if (!listening) {
    return;
  }

  exceptions =
    excluded_blocks &&
    excluded_blocks.length &&
    excluded_blocks.map(b => b.value).join(",");

  // Dispatch all queued mutations only for connect
  // (because in connect there are no initial JS translation when lib is ready)
  if (is_connect && mutationQueues.length > 0) {
    // Dispatch it asynchronously not to block the site if lots of mutations to manage
    for (const { mutations, observedDocument } of mutationQueues) {
      const asyncDispatchQueuedMutations = () => {
        const mutationsChunk = mutations.splice(0, 100);
        if (mutationsChunk.length > 0) {
          dispatchMutations(mutationsChunk, observedDocument);
          setTimeout(asyncDispatchQueuedMutations, 0);
        }
      };

      asyncDispatchQueuedMutations();
    }
  } else {
    mutationQueues = [];
  }
}

export function disconnectDynamic() {
  if (observers.length === 0) {
    return;
  }
  for (const observer of observers) {
    observer.disconnect();
  }
  mutationQueues = [];
}

export function observeBodyNodes(observedDocument) {
  const isIframe = observedDocument !== document;

  const rootNode = isIframe
    ? observedDocument
    : observedDocument.body || observedDocument;

  const observer = new MutationObserver(mutations => {
    if (!listening) {
      // MutationObserver is started as early as we can
      // because sometimes (e.g. with Salesforce sites)
      // changes are not detected.
      // So we start it very early (before between polyfill and options fetch)
      // but we ignore all mutations until we ask for it (after initial translation)
      const queue = mutationQueues.find(
        mq => mq.observedDocument === observedDocument
      );
      if (queue) {
        queue.mutations.push(...mutations);
      } else {
        mutationQueues.push({ observedDocument, mutations: [...mutations] });
      }
      return;
    }

    dispatchMutations(mutations, observedDocument);
  });
  observer.observe(rootNode, {
    childList: true,
    subtree: true,
    characterData: true,
    attributes: true,
  });
  observers.push(observer);
}

function filterMutations(mutations, dynamicCheck) {
  const setted = [];
  const filtered = mutations.filter(mutation => {
    const { addedNodes, type, target } = mutation;
    // Set notranslate property as early as we can on mutated nodes
    setNoTranslate(target);

    if (type === "attributes") {
      setLinkHooks(target);
      checkImages(target);
    }
    // We detect nodes as freshly setted
    const settedNode = settedClosest(target);
    if (settedNode) {
      setted.push(settedNode);
      return false;
    }
    if (addedNodes.length) {
      setTimeout(() => onNewNodes(target, addedNodes));
      // We detect nodes in an excluded blocks, ignore them
      return !exceptions || !target || !safeClosest(target, exceptions);
    }
    // Properly added in a node, check only if its dynamic
    return (
      !excludedAttributes.includes(mutation.attributeName) &&
      dynamicCheck(target) &&
      (type === "characterData" || type === "attributes")
    );
  });
  if (setted.length) {
    for (const node of setted) {
      node.weglot.setted = false;
    }
  }
  return filtered;
}

export function isEligibleDynamic(node) {
  if (!options.dynamics || options.dynamics.length === 0 || !node) {
    return false;
  }

  if (!node.closest) {
    return isEligibleDynamic(node.parentNode);
  }

  // Classic closest to check node ancestors if they match
  const match = !!safeClosest(
    node,
    options.dynamics.map(d => d.value).join(", ")
  );

  // If we are in a shadow root, get out of it to check again on the host node
  if (!match && node.getRootNode && node.getRootNode().host) {
    return isEligibleDynamic(node.getRootNode().host);
  }

  return match;
}

function checkImages(node) {
  // If there is mutation on images which are already translated, throw srcset
  // because it fucked up the translated src -> lazy loading fix
  if (node.nodeName === "IMG" && node.srcset && node.dataset.wgtranslated) {
    node.setAttribute("wgsrcset", node.srcset);
    node.srcset = "";
  }
}

function isOverDynamic(element) {
  if (element.weglot && element.weglot.dynamic > 20) {
    if (
      element.wgBypassDynamicLimit ||
      (element.closest &&
        options.dangerously_bypass_dynamic_limit &&
        safeClosest(
          element,
          options.dangerously_bypass_dynamic_limit.map(s => s.value).join(", ")
        ))
    ) {
      element.wgBypassDynamicLimit = true;
      return false;
    }

    return true;
  }

  return false;
}

function parseMutation(mutations, dynamicCheck, detectiFrame = true) {
  const nodes = [];
  const addNewHTMLNodes = node => {
    // Parse dynamic node only once if it didn't change

    const parsedHTML = node.outerHTML || node.textContent;
    if (node.wgParsedHTML === parsedHTML) {
      return;
    }

    node.wgParsedHTML = parsedHTML;

    // parse node with dynamic predicate
    const elements = parseNodes(
      node,
      ({ element }) => !isOverDynamic(element) && dynamicCheck(element)
    );
    for (const element of elements) {
      if (options.ignoreDynamicFragments && !document.body.contains(element)) {
        // Ignore mutations coming from elements that are not in the document
        // e.g.: DocumentFragment
        continue;
      }

      if (!element.weglot.dynamic) {
        element.weglot.dynamic = 0;
      }

      element.weglot.dynamic++;
      nodes.push(element);
    }

    return null;
  };

  const mutated = [];
  for (let mutation of mutations) {
    const { type, target, addedNodes } = mutation;
    // Mutations are either CharacterData or AddedNodes
    switch (type) {
      case "attributes":
      case "characterData":
        if (mutated.includes(target)) {
          break;
        }
        mutated.push(target);
        addNewHTMLNodes(target);
        break;
      case "childList": {
        const element = addedNodes.length > 1 ? target : addedNodes[0];
        if (mutated.includes(element)) {
          break;
        }
        addNewHTMLNodes(element);
        mutated.push(element);
        if (!detectiFrame) {
          break;
        }
        for (let addedNode of addedNodes) {
          let iframes = [];
          if (addedNode.tagName === "IFRAME") {
            iframes = [addedNode];
          } else if (addedNode.querySelectorAll) {
            iframes = addedNode.querySelectorAll("iframe");
          }
          for (let index = 0; index < iframes.length; index++) {
            const iframe = iframes[index];
            if (
              dynamicCheck(iframe) &&
              isIframeAccessible(iframe) &&
              !isNoTranslate(iframe)
            ) {
              addNewHTMLNodes(iframe.contentWindow.document);
              observeBodyNodes(iframe.contentWindow.document);
            }
          }
        }
        break;
      }
      default:
        break;
    }
  }

  if (nodes.length) {
    addNodes(nodes);
    translateNewNodes(nodes);
  }
}

const queue = { times: [], timeout: null, nodes: [] };
export function translateNewNodes(nodesToTranslate = []) {
  clearTimeout(queue.timeout);

  const getDynamicSelector = node => {
    if (!node) {
      return "unknown";
    }

    if (!node.closest) {
      node = node.parentNode;
    }

    for (const dynamic of options.dynamics) {
      if (safeClosest(node, dynamic.value)) {
        return dynamic.value;
      }
    }

    if (node.getRootNode && node.getRootNode() && node.getRootNode().host) {
      // Shadow Root -> check the host outside the shadow root
      return getDynamicSelector(node.getRootNode().host);
    }

    return "unknown";
  };

  const currentLanguage = getCurrentLanguage();
  if (currentLanguage === options.language_from) {
    return;
  }

  queue.times = queue.times.filter(time => time > Date.now() - 1000);
  if (
    queue.times.length &&
    (queue.timeout || queue.times.length >= maxApiRequestSecond)
  ) {
    queue.nodes = queue.nodes.concat(nodesToTranslate);
    queue.timeout = setTimeout(() => translateNewNodes(), 1000);
    return;
  }

  nodesToTranslate.forEach(node => {
    node.translationLabel = `dynamic-selector: ${getDynamicSelector(node)}`;
  });

  queue.timeout = null;
  queue.times.push(Date.now());
  const nodes = queue.nodes.concat(nodesToTranslate);
  queue.nodes = [];
  const payload = getPayload(nodes, { label: "Dynamic" });
  const opts = {
    title: false,
    cdn: true,
    nodes,
  };

  return translate(payload, currentLanguage, opts).then(translatedWords =>
    setWords(translatedWords, currentLanguage, nodes)
  );
}

function replaceLinks(nodes) {
  const currentHost = window.location.hostname;
  if ([options.host, ...editorHostnamesValues].includes(currentHost)) {
    return;
  }
  for (const node of nodes) {
    const nodesWithHref = safeQuerySelectorAll(node, "[href]");
    for (const nodeHref of nodesWithHref) {
      if (isNoTranslate(nodeHref)) {
        continue;
      }
      const href = nodeHref.getAttribute("href");
      if (!href || !href.includes(`//${options.host}`)) {
        continue;
      }
      const [, pathname = "/"] = href.split(`//${options.host}`);
      if (isExcludedPath(undefined, pathname)) {
        continue;
      }
      getSlugs(slugs => {
        nodeHref.setAttribute(
          "href",
          getURL(getCurrentLanguage(), slugs, href)
        );
      });
    }
  }
}

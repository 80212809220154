import {
  isEligibleDynamic,
  observeBodyNodes,
  translateNewNodes,
} from "../nodes/dynamic";
import { addNodes, parseNodes } from "../nodes/nodes";
import options from "../options/options";

try {
  const attachShadow = Element.prototype.attachShadow;
  Element.prototype.attachShadow = function (option) {
    const shadow = attachShadow.call(this, option);
    translateShadowRoots([this]);
    return shadow;
  };
} catch (_) {} // eslint-disable-line

function translateShadowRoots(nodes) {
  if (!options.translate_shadow_roots || !nodes) {
    return;
  }

  for (const node of nodes) {
    if (!node.shadowRoot || node.shadowRoot.wgTranslated) {
      continue;
    }
    node.shadowRoot.wgTranslated = true;
    observeBodyNodes(node.shadowRoot);

    const nodes = parseNodes(node.shadowRoot);
    if (!nodes.length) {
      continue;
    }
    addNodes(nodes);
    translateNewNodes(nodes.filter(n => isEligibleDynamic(n)));
  }
}

export default translateShadowRoots;

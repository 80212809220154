import cookies from "common/helpers/cookies";
import options from "../weglot/options/options";

const cookieStorage = {
  getItem: key => cookies.get(key),
  setItem: (key, value, otherParams = {}) => {
    const { domain, path, expires } = otherParams;
    cookies.set({ name: key, value, domain, path, expires, options });
  },
  removeItem: key => cookies.erase({ name: key, options }),
};

const noStorage = {
  getItem: () => {},
  setItem: () => {},
  removeItem: () => {},
};

// Weglot storage methods. Could be type: local or cookie
export default function storage(params = {}) {
  const type = params.type || "local";
  try {
    // Test if we can access this storage
    const store = type === "cookie" ? cookieStorage : window[`${type}Storage`];
    return store;
  } catch (_) {} // eslint-disable-line

  // If we need a type and it's not available, return nothing
  if (params.type) {
    return noStorage;
  }

  // Can't access to asked storage, try another
  return storage({ type: type === "local" ? "cookie" : "local" });
}

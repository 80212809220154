import { isEditorHost } from "common/helpers/editors";
import onDocumentReady from "common/helpers/onDocumentReady";
import { addStyleToHead, getInjectedWeglotData } from "common/helpers/utils";
import { cssUrl } from "common/constants";

import {
  DEFAULT_OPTIONS,
  listOptions,
  settingsUrl,
  previewDomain,
  sourceApiHost,
  proxyDedicatedHost,
  proxyFormats,
  technologies,
} from "../constants";
import loadTechnology from "../technology/index";
import { convertToV3 } from "./convert-v3";
import {
  concatenate,
  loadRemoteCSS,
  isSelectorValid,
} from "../../utils/helpers";
import { triggerEvent } from "../../utils/events";
import isPrivateMode from "../helpers/isPrivateMode";
import logger from "../../utils/logger";

const options = {};

/**
 * V2 - Set options from webpage setup
 * @param {object} userOpts
 * @returns {object}
 */
export function setOptions(userOpts) {
  try {
    const fields = ["api_key", "originalLanguage", "destinationLanguages"];
    if (!userOpts || fields.some(field => !userOpts[field])) {
      throw {
        wgErrMsg: `You have to provide at least: ${fields.join(", ")}`,
      };
    }
    const options = convertToV3(userOpts);
    // Check normalized options
    return checkOptions(options);
  } catch (e) {
    throw new Error((e && e.wgErrMsg) || "Error while reading Weglot options");
  }
}

function mergeSettings(settings, custom_settings, overOptions) {
  const mergedKey = ["excluded_blocks", "dynamics"];

  const mergedSettings = Object.assign(
    {},
    settings,
    custom_settings,
    overOptions
  );

  for (const key of mergedKey) {
    mergedSettings[key] = Array.from(
      new Set([
        ...(settings[key] || []),
        ...(custom_settings[key] || []),
        ...(overOptions[key] || []),
      ])
    );
  }

  if (mergedSettings.dynamic) {
    // Merge and convert legacy dynamic
    mergedSettings.dynamics = mergedSettings.dynamics.concat(
      mergedSettings.dynamic
        .split(",")
        .map(selector => ({ value: selector.trim() }))
    );

    delete mergedSettings.dynamic;
  }

  return mergedSettings;
}

/**
 * V3 - Fetch options from CDN
 * @param {object} userOpts
 * @returns {Promise<object>}
 */
export function fetchOptions(userOpts) {
  if (!userOpts || !userOpts.api_key) {
    throw Error("You have to provide at least a key");
  }
  const key = userOpts.api_key.split("wg_").pop();
  // Options in Weglot.initialize() overrides json options. We convert and prioritize them.
  const overOptions = convertToV3(userOpts);

  return (
    getOptions(key)
      // Flatten json options
      .then(json => {
        const { custom_settings, ...settings } = json;
        overOptions.button_style = Object.assign(
          custom_settings ? custom_settings.button_style : {},
          overOptions.button_style
        );
        // language_from and languages cannot be overrided
        const { language_from, languages } = settings;
        if (language_from) {
          overOptions.language_from = language_from;
        }
        if (languages) {
          overOptions.languages = languages;
        }

        if (custom_settings && custom_settings.localeRules) {
          overOptions.localeRules = custom_settings.localeRules;
        }
        // Override options if they are in HTML
        const sett = mergeSettings(settings, custom_settings, overOptions);

        const checkedOptions = checkOptions(sett);

        triggerEvent("onOptionsReady");
        return checkedOptions;
      })
      .catch(e => {
        logger.error(e, {
          consoleOverride:
            (e && e.wgErrMsg) ||
            "Cannot fetch Weglot options. Is your key correct?",
          sendToDatadog: false,
        });
      })
  );
}

function addInjectedData() {
  const injectedData = getInjectedWeglotData();
  if (!injectedData) {
    return;
  }
  delete injectedData.settings;
  options.injectedData = injectedData;
}

function getPreviewHash(host) {
  if (!host.includes(previewDomain)) {
    return null;
  }

  const [deepestSubdomain] = host.split(".");
  if (deepestSubdomain.includes("-")) {
    // We are in a language subdomain
    const [hash] = deepestSubdomain.split("-").reverse();
    return hash;
  }

  // We are on the main site preview domain
  return deepestSubdomain;
}

function getOptions(key) {
  if (isEditorHost(window.location.hostname)) {
    return fetch(
      `https://${sourceApiHost}/projects/settings?api_key=wg_${key}`
    ).then(data => data.json());
  }

  // Check if we have a weglot-data block with the settings sent by connect and use them
  const scriptWeglotData = getInjectedWeglotData();
  if (scriptWeglotData && scriptWeglotData.settings) {
    const { settings, ...injectedData } = scriptWeglotData;
    settings.injectedData = injectedData;
    return Promise.resolve(settings);
  }

  return fetch(`${settingsUrl}${key}.json`).then(data => data.json());
}

/**
 * Normalizes v2 & v3 options
 * @param {object} settings
 * @returns {object}
 */
function checkOptions(settings) {
  if (settings.deleted_at) {
    throw {
      wgErrMsg: "Cannot load Weglot because the project has been deleted",
    };
  }

  // If tried to load injectedData before page loaded, injectedData still be undefined.
  if (!settings.injectedData) {
    onDocumentReady(addInjectedData);
  }

  if (settings.url_type === "SUBDIRECTORY" && settings.is_dns_set) {
    settings.subdirectory = true;
  }

  const previewHash = getPreviewHash(window.location.hostname);
  if (previewHash) {
    settings.previewHash = previewHash;
    settings.is_dns_set = true;
    settings.subdirectory = window.location.hostname.startsWith("subdir-");
  }

  if (!settings.languages.length) {
    settings.languages = [];
  }

  options.is_connect =
    settings.subdirectory ||
    settings.languages.some(
      l =>
        l.connect_host_destination &&
        l.connect_host_destination.is_dns_set &&
        l.connect_host_destination.created_on_aws
    );

  if (isEditorHost(window.location.hostname)) {
    if (window.location.hostname.includes("switchers")) {
      options.switcher_editor = true;
    }
    options.visual_editor = true;
  }

  options.private_mode = isPrivateMode();

  // Get options from specific technology
  if (!options.technology_id) {
    const technologyName = settings.technology_name || options.technology_name;
    if (technologyName && technologies[technologyName]) {
      settings.technology_id = technologies[technologyName].id;
      settings.technology_name = technologies[technologyName].name;
    }
  }
  const technologyOptions = loadTechnology(settings.technology_id);

  // Redefine default options with specifics technology options
  const defaultOptions = Object.assign({}, DEFAULT_OPTIONS, technologyOptions);

  // Assign settings to defaultOptions to global options
  Object.assign(options, defaultOptions, settings);

  if (options.whitelist && !Array.isArray(options.whitelist)) {
    options.whitelist = String(options.whitelist)
      .split(",")
      .map(value => ({ value }));
  }

  // Concatenate some technology options with global options
  listOptions.forEach(function (key) {
    if (options[key] !== defaultOptions[key]) {
      options[key] = concatenate(options[key], defaultOptions[key]);
    }
  });

  loadRemoteCSS(cssUrl);
  // Add global css
  if (options.button_style && options.button_style.custom_css) {
    addStyleToHead(options.button_style.custom_css, "weglot-custom-style");
  }

  if (!options.switchers || options.switchers.length === 0) {
    options.switchers = [
      {
        style: options.button_style,
        location: {},
        default: true, // this is a special switcher
      },
    ];
  } else {
    options.switchers = options.switchers.map(
      ({ button_style, ...switcher }) => ({
        style: switcher.style || button_style,
        ...switcher,
      })
    );
  }

  // This is how we detect a translation engine v1
  if (options.api_key.length < 36) {
    options.translation_engine = 1;
  }

  // Disable default exclusions
  if (options.excluded_blocks_remove) {
    options.excluded_blocks = options.excluded_blocks.filter(
      excluded => !options.excluded_blocks_remove.includes(excluded.value)
    );
  }

  // Add force dynamic to dynamic options
  // For backward compatibility: dangerously_force_dynamic is not used anymore
  if (options.dangerously_force_dynamic) {
    options.dynamics = [
      ...options.dynamics,
      ...options.dangerously_force_dynamic
        .split(",")
        .map(selector => ({ value: selector.trim() })),
    ];
  }

  if (Array.isArray(options.dangerously_bypass_dynamic_limit)) {
    options.dangerously_bypass_dynamic_limit =
      options.dangerously_bypass_dynamic_limit
        .map(o => (o.value ? o.value : o))
        .join(",");
  }

  // Remove invalid CSS selectors (i.e., those used for XML)
  options.excluded_blocks = options.excluded_blocks.filter(b =>
    isSelectorValid(b.value)
  );
  options.dynamics = options.dynamics.filter(b => isSelectorValid(b.value));

  // Disable default dynamics
  if (options.dynamics_remove) {
    options.dynamics = options.dynamics.filter(
      dynamic => !options.dynamics_remove.includes(dynamic.value)
    );
  }

  // If Connect, check if we are TLD and add to options.
  options.is_tld = false;

  // Disable Connect on Squarespace configuration page
  if (technologyOptions.forceDisableConnect) {
    options.is_connect = false;
  }

  if (options.is_connect && !options.previewHash) {
    const domain = options.host.split("www.").pop();
    options.is_tld = options.languages.some(language => {
      if (
        language.connect_host_destination &&
        language.connect_host_destination.host
      ) {
        return language.connect_host_destination.host.indexOf(domain) === -1;
      }
    });
  }

  // force prevent_retranslation to true if body is setted as dynamic
  if (!options.prevent_retranslation) {
    options.prevent_retranslation = options.dynamics.some(
      d => d.value === "body"
    );
  }

  options.proxyFormat = proxyFormats.dedicated;
  if (
    options.is_connect &&
    !options.disable_internal_proxy &&
    window.location.hostname !== proxyDedicatedHost
  ) {
    options.proxyFormat = proxyFormats.internal;
  }

  // format force_translation to string
  if (Array.isArray(options.force_translation)) {
    options.force_translation = options.force_translation.join(",");
  }

  return options;
}

export function applyOptions(boptions) {
  Object.assign(options, boptions);
}

export default options;

import { useEffect, useState, useRef } from "preact/hooks";

import getLanguageURL from "library/src/weglot/helpers/getLanguageURL.js";

import {
  getAvailableLanguages,
  getCurrentLanguage,
  getRect,
  keycodes,
  addStyleTag,
  switchTo,
  buildCSS,
} from "./utils";
import options from "./options";
import { mobileDeviceCutoff } from "./constants";

// Returns stateful object mapping language to URLs, { "en": "url1", ... }.
// Until slugs fetched, every language maps to an empty string.
export function useLanguageUrls() {
  const languages = getAvailableLanguages();
  const [urls, setUrls] = useState(
    languages.reduce((all, l) => ({ ...all, [l]: "" }), {})
  );
  useEffect(() => {
    Promise.all(
      languages.map(
        l => new Promise(res => getLanguageURL(l, url => res({ l, url })))
      )
    ).then(fetched =>
      setUrls(fetched.reduce((all, { l, url }) => ({ ...all, [l]: url }), {}))
    );
  }, []);
  return urls;
}

// Returns stateful variable, language, initially the current Weglot language, and a setter
// to update it. Listens for changes from another switcher and updates state accordingly.
export function useSwitcherLanguage() {
  const [language, setLanguage] = useState(getCurrentLanguage());
  useEffect(() => {
    if (
      options.is_connect ||
      options.technology_name === "WordPress" ||
      options.switcher_editor
    ) {
      return;
    }
    window["Weglot"].on("languageChanged", newLang => {
      setLanguage(newLang);
    });
  }, []);

  return [language, setLanguage];
}

// Returns a reference to be added to a Preact element. So long as the element is rendered,
// it will listen for any clicks on the DOM outside of itself and trigger the callback.
// The listener is removed when the component is unmounted.
function useOutsideListener(callback) {
  const ref = useRef(null);
  useEffect(() => {
    if (!callback) {
      return;
    }
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return ref;
}

function matchesMediaQuery(device, pixel_cutoff) {
  const width = window.innerWidth > 0 ? window.innerWidth : screen.width;
  const threshold = pixel_cutoff || mobileDeviceCutoff;
  if (device === "mobile") {
    return width <= threshold;
  }
  return width > threshold;
}

// Returns a stateful variable, visible, initially true. It is set to false if the
// is_responsive option is set and the current device does not match the
// display_device option.
export function useResponsiveDisplay({
  is_responsive,
  display_device,
  pixel_cutoff,
}) {
  const [visible, setVisible] = useState(
    is_responsive ? matchesMediaQuery(display_device, pixel_cutoff) : true
  );
  const handleResize = () =>
    setVisible(matchesMediaQuery(display_device, pixel_cutoff));

  useEffect(() => {
    if (!is_responsive) {
      // Nothing to do, always visible.
      return;
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [is_responsive, display_device, pixel_cutoff]);
  return visible;
}

export function useStyleTag(switcherProps, createStyle, templateName) {
  const [hasStyleTag, setHasStyleTag] = useState(false);
  const { style = {}, colors = {} } = switcherProps;
  useEffect(() => {
    const { size_scale } = style;
    if (templateName === "default" && (!size_scale || size_scale === 1)) {
      // For default template, only add custom CSS if needs resizing.
      return;
    }
    const tagContents = buildCSS(createStyle({ style, colors }), templateName);
    addStyleTag(tagContents, templateName);
    setHasStyleTag(true);
  }, []);
  return hasStyleTag;
}

export function useDropdown({
  close_outside_click = false,
  open_hover = false,
}) {
  const [language, setLanguage] = useSwitcherLanguage();
  const [open, setOpen] = useState(false);
  const switcherContainerRef = useOutsideListener(
    close_outside_click && !open_hover && handleClose
  );
  const focusedLanguageRef = useRef(null);
  const otherLanguages = getAvailableLanguages().filter(l => l !== language);
  const [focusedLanguage, setFocusedLanguage] = useState(null);
  const [opensLeftward, setOpenLeftward] = useState(false);
  const [opensUpward, setOpenUpward] = useState(false);

  function handleOpen() {
    const { bottom = 0, left = 0 } = getRect(switcherContainerRef.current);
    setOpenUpward(bottom > window.innerHeight / 2);
    setOpenLeftward(left > window.innerWidth / 2);
    setOpen(true);
  }

  function handleMouseEnter() {
    if (open_hover) {
      handleOpen();
    }
  }

  function handleMouseLeave() {
    if (open_hover) {
      handleClose();
    }
  }

  function handleClose() {
    setOpen(false);
    setFocusedLanguage(null);
  }

  function toggleOpen() {
    if (open) {
      return handleClose();
    }
    return handleOpen();
  }

  function handleKeyDown(e) {
    if (e.keyCode === keycodes.enter) {
      // Toggle switcher
      e.preventDefault();
      if (focusedLanguage) {
        switchLanguage(focusedLanguage);
      }
      return toggleOpen();
    }
    if (e.keyCode === keycodes.arrowDown || e.keyCode === keycodes.arrowUp) {
      e.preventDefault();
      moveFocus(e.keyCode);
      return;
    }
    if (e.keyCode === keycodes.escape && open) {
      // Close switcher
      e.preventDefault();
      toggleOpen();
    }
  }

  function moveFocus(keyCode) {
    const direction = keyCode === keycodes.arrowDown ? "down" : "up";
    const lastLanguage = otherLanguages.slice(-1).pop();
    const firstLanguage = otherLanguages[0];
    const { bottom = 0 } = getRect(switcherContainerRef.current);
    const opensUp = bottom > window.innerHeight / 2;
    if (!focusedLanguage || !open) {
      const newFocus = direction === "down" ? firstLanguage : lastLanguage;
      setFocusedLanguage(newFocus);
      const needToOpen =
        !open &&
        ((direction === "up" && opensUp) || (direction === "down" && !opensUp));
      if (needToOpen) {
        handleOpen();
      }
      return;
    }
    const needsToClose =
      (!opensUp && direction === "up" && focusedLanguage === firstLanguage) ||
      (opensUp && direction === "down" && focusedLanguage === lastLanguage);
    if (needsToClose) {
      toggleOpen();
      return;
    }
    const shift = direction === "up" ? -1 : +1;
    const nextPosition = otherLanguages.indexOf(focusedLanguage) + shift;
    if (nextPosition === otherLanguages.length || nextPosition === -1) {
      return;
    }
    setFocusedLanguage(otherLanguages[nextPosition]);
  }

  function switchLanguage(l) {
    if (options.switcher_editor) {
      return setOpen(false);
    }
    setLanguage(l);
    switchTo(l);
    setOpen(false);
  }

  useEffect(() => {
    if (focusedLanguage) {
      focusedLanguageRef.current.scrollIntoView({ block: "center" });
    }
  }, [focusedLanguage]);

  return {
    open,
    opensUpward,
    opensLeftward,
    language,
    otherLanguages,
    focusedLanguage,
    switcherContainerRef,
    focusedLanguageRef,
    handleMouseEnter,
    handleMouseLeave,
    handleKeyDown,
    switchLanguage,
    toggleOpen,
  };
}

import { debounce, unescapeHTMLEntities } from "../../utils/helpers";
import storage from "../../utils/storage";
import { cacheKey } from "../constants";
import options from "../options/options";

const versionCacheKey = `${cacheKey}-v`;
const localStorage = storage({ type: "local" });
const storageVersions = safeParseJSON(localStorage.getItem(versionCacheKey));
const cache = safeParseJSON(localStorage.getItem(cacheKey));
const translations = {};

function safeParseJSON(json) {
  if (!json) {
    return {};
  }
  try {
    const parsed = JSON.parse(json);
    if (typeof parsed !== "object") {
      return {};
    }
    return parsed;
  } catch (e) {
    return {};
  }
}

try {
  Object.keys(cache).forEach(apiKey => {
    Object.keys(cache[apiKey]).forEach(original => {
      Object.keys(cache[apiKey][original]).forEach(language => {
        if (language.length !== 2) {
          return;
        }
        if (!translations[apiKey]) {
          translations[apiKey] = {};
        }
        if (!translations[apiKey][language]) {
          translations[apiKey][language] = {};
        }
        const translation = cache[apiKey][original][language];
        translations[apiKey][language][translation] = original;
      });
    });
  });
} catch (e) {
  // Problem during parsing: Empty cache
  Object.keys(cache).forEach(apiKey => {
    delete cache[apiKey];
  });
  Object.keys(storageVersions).forEach(apiKey => {
    delete storageVersions[apiKey];
  });
  updateLocalStorage();
}

export function validateCache() {
  const storedVersion = storageVersions[options.api_key];
  const isValid =
    storedVersion && storedVersion === options.versions.translation;

  if (!isValid) {
    // Delete all storage items associated with this project
    delete cache[options.api_key];
    delete storageVersions[options.api_key];
    delete translations[options.api_key];
    updateLocalStorage();
  }
  return isValid;
}

/**
 * Get words from cache
 * @param {object[]} words
 * @param {string} lang
 * @returns {object}
 */
export function queryCache(words, lang) {
  const to_words = [];
  const from_words = [];
  const allWordsCached = words.every(wordEntry => {
    const cached = cacheFindEntry(wordEntry.w);
    if (cached && cached[lang]) {
      to_words.push(cached[lang]);
      from_words.push(unescapeHTMLEntities(wordEntry.w));
      return true;
    }
  });
  return {
    isComplete: allWordsCached,
    words: { to_words, from_words },
  };
}

export function isCachedWord(word, language) {
  if (
    !translations[options.api_key] ||
    !translations[options.api_key][language]
  ) {
    return false;
  }
  return translations[options.api_key][language][word];
}

function cacheFindEntry(originalWord) {
  return cache[options.api_key] && cache[options.api_key][originalWord];
}

export function cacheSet(originalWord, type, translatedWord, language) {
  const cachedEntry = cacheFindEntry(originalWord);
  if (cachedEntry) {
    cachedEntry[language] = translatedWord;
  } else {
    if (!cache[options.api_key]) {
      cache[options.api_key] = {};
    }

    cache[options.api_key][originalWord] = {
      [language]: translatedWord,
    };
  }
  if (!translations[options.api_key]) {
    translations[options.api_key] = {};
  }
  if (!translations[options.api_key][language]) {
    translations[options.api_key][language] = {};
  }
  translations[options.api_key][language][translatedWord] = originalWord;
  storageVersions[options.api_key] = options.versions.translation;
  debounce(updateLocalStorage)();
}

function updateLocalStorage() {
  try {
    localStorage.setItem(versionCacheKey, JSON.stringify(storageVersions));
    localStorage.setItem(cacheKey, JSON.stringify(cache));
  } catch (_) {
    // Error setting storage, likely due to it being full. Ignore.
  }
}

export function getCache() {
  return cache[options.api_key] || {};
}

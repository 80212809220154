/*{
  name: Must match the folder name of the switcher, used for code imports and for CDN asset filename.
  min/maxLangauges: Minimum or maximum number of languages a project can have to use this switcher.
  editableProps: Any options added here will be visible in the Switcher Editor.
  defaultValues: Default property values of switcher's style / options. 
  editorDropdown: Only controls styling on left-hand preview in Switcher Editor.
}*/

// Default styles for all switchers, overriden by template.defaultStyles for each template.

const textColors = ["text_active", "text_inactive", "text_hover"];
const bgColors = ["bg_active", "bg_inactive", "bg_hover"];

const templateMetaData = [
  {
    name: "default",
    editorDropdown: true,
    editableProps: [
      "flag_type",
      "with_flags",
      "is_dropdown",
      "with_name",
      "full_name",
      "invert_flags",
      "open_hover",
      "close_outside_click",
    ],
    defaultValues: {
      style: {
        with_name: true,
        with_flags: true,
        full_name: true,
        is_dropdown: true,
        invert_flags: true,
        flag_type: "rectangle_mat",
      },
      opts: {
        open_hover: false,
        close_outside_click: false,
      },
    },
  },
  {
    name: "toggle",
    editableProps: [...textColors, ...bgColors],
    defaultValues: {
      style: {
        full_name: false,
      },
      colors: {
        bg_active: "#3D46FB",
        bg_inactive: "transparent",
        bg_hover: "transparent",
        text_active: "#FFFFFF",
        text_inactive: "#000000",
        text_hover: "#000000",
      },
    },
  },
  {
    name: "bubble",
    editorDropdown: true,
    editableProps: [
      "flag_type",
      "with_flags",
      "full_name",
      "color",
      "open_hover",
      "close_outside_click",
      ...textColors,
    ],
    defaultValues: {
      style: {
        with_name: true,
        with_flags: true,
        full_name: true,
        flag_type: "rectangle_mat",
      },
      opts: {
        open_hover: false,
        close_outside_click: true,
      },
      colors: {
        text_inactive: "#333333",
        text_active: "#555555",
        text_hover: "#555555",
      },
    },
  },
  {
    name: "vertical_expand",
    editorDropdown: true,
    editableProps: [
      "with_flags",
      "full_name",
      "color",
      "open_hover",
      "close_outside_click",
      ...textColors,
      ...bgColors,
    ],
    defaultValues: {
      style: {
        with_name: true,
        with_flags: true,
        full_name: false,
        flag_type: "square",
      },
      opts: {
        open_hover: true,
        close_outside_click: false,
      },
      colors: {
        text_active: "#000000",
        text_inactive: "#000000",
        text_hover: "#FFFFFF",
        bg_inactive: "#FFFFFF",
        bg_active: "#FFFFFF",
        bg_hover: "#3D46FB",
      },
    },
  },
  {
    name: "horizontal_expand",
    editorDropdown: false,
    editableProps: [
      "open_hover",
      "close_outside_click",
      ...textColors,
      ...bgColors,
    ],
    defaultValues: {
      style: {
        with_name: true,
        with_flags: false,
        full_name: false,
        flag_type: "square",
      },
      opts: {
        open_hover: true,
        close_outside_click: false,
      },
      colors: {
        text_inactive: "#000000",
        text_active: "#FFFFFF",
        text_hover: "#FFFFFF",
        bg_inactive: "#FFFFFF",
        bg_active: "#3D46FB",
        bg_hover: "#3D46FB",
      },
    },
  },
  {
    name: "underline_edge",
    editableProps: ["full_name", ...textColors],
    maxLanguages: 10,
    minLanguages: null,
    defaultValues: {
      style: {
        full_name: false,
      },
      colors: {
        text_active: "#FA8072",
        text_inactive: "#333333",
        text_hover: "#FA8072",
      },
    },
  },
  {
    name: "skewed",
    editorDropdown: true,
    editableProps: [
      "with_flags",
      "full_name",
      "open_hover",
      "close_outside_click",
      "bg_active",
      "bg_inactive",
      ...textColors,
    ],
    defaultValues: {
      style: {
        with_name: true,
        with_flags: true,
        full_name: false,
        flag_type: "square",
      },
      opts: {
        open_hover: true,
        close_outside_click: false,
      },
      colors: {
        text_active: "#000000",
        text_inactive: "#000000",
        text_hover: "#3D46FB",
        bg_inactive: "#FFFFFF",
        bg_active: "transparent",
        bg_hover: "#FFFFFF",
      },
    },
  },
  {
    name: "underline_full",
    maxLanguages: 10,
    minLanguages: null,
    editableProps: ["with_flags", "flag_type", ...textColors],
    defaultValues: {
      style: {
        full_name: true,
        with_flags: true,
        flag_type: "rectangle_mat",
      },
      colors: {
        text_active: "#333333",
        text_inactive: "#333333",
        text_hover: "#3D46FB",
      },
    },
  },
].map(addCommonPropsAndDefaults);

// Add properties common to all switchers.
function addCommonPropsAndDefaults(template) {
  const commonProps = [
    "is_responsive",
    "display_device",
    "pixel_cutoff",
    "size_scale",
  ];

  return {
    ...template,
    defaultValues: {
      ...template.defaultValues,
      opts: {
        ...template.defaultValues.opts,
        is_responsive: false,
        display_device: "mobile",
        pixel_cutoff: 768,
      },
      style: {
        ...template.defaultValues.style,
        size_scale: 1,
      },
    },
    editableProps: [...template.editableProps, ...commonProps],
  };
}

export function getTemplateMetaData(name = "default") {
  return templateMetaData.find(t => t.name === (name || "default"));
}

export function getDefaultSwitcherProps(name) {
  const { defaultValues: { style = {}, opts = {}, colors = {} } = {} } =
    getTemplateMetaData(name);
  return {
    style,
    opts,
    colors,
  };
}

// Exports a list of all available switcher template names. If project settings are provided
// the list is filtered according to the relevant template metadata (number of languages, etc).
export function getTemplateNames(settings) {
  if (!settings) {
    // No settings provided, so all templates are allowed.
    return templateMetaData.map(({ name }) => name);
  }
  const { languages } = settings;
  return templateMetaData
    .filter(template => {
      const { maxLanguages = null, minLanguages = null } = template;
      if (maxLanguages && maxLanguages < languages.length) {
        return false;
      }
      if (minLanguages && minLanguages > languages.length) {
        return false;
      }
      return true;
    })
    .map(template => template.name);
}

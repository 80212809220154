import { h, Fragment } from "preact";
import clsx from "clsx";

import {
  getAvailableLanguages,
  getLanguageName,
  renderSwitcher,
} from "../../shared/utils";
import {
  useDropdown,
  useLanguageUrls,
  useResponsiveDisplay,
  useStyleTag,
} from "../../shared/hooks";
import options from "../../shared/options";
import Option from "./Option";
import createStyle from "./style.js";
import { useEffect, useRef, useState } from "preact/hooks";

const switcherName = "default";

const Switcher = ({ style, opts }) => {
  // Hooks
  const {
    open,
    opensUpward,
    opensLeftward,
    language,
    focusedLanguage,
    switcherContainerRef,
    focusedLanguageRef,
    handleMouseEnter,
    handleMouseLeave,
    handleKeyDown,
    switchLanguage,
    toggleOpen,
  } = useDropdown(opts);
  const urls = useLanguageUrls();
  const responsiveVisible = useResponsiveDisplay(opts);
  useStyleTag({ style }, createStyle, switcherName);

  //width handler
  const otherLanguagesRef = useRef(null);
  const [optionWidth, setOptionWidth] = useState(0);
  useEffect(() => {
    if (otherLanguagesRef && open) {
      setOptionWidth(otherLanguagesRef.current.offsetWidth);
    }
  }, [open, otherLanguagesRef]);

  const { is_dropdown, invert_flags } = style;

  const moveSelectedLangToFront = is_dropdown || invert_flags;
  const listLanguages = getAvailableLanguages().filter(l => {
    if (!moveSelectedLangToFront) {
      // Switcher languages keep same order, so listLanguages is all languages.
      return true;
    }
    // Selected language moves to front, so remove it from listLanguages.
    return l !== language;
  });
  const legacyFlags =
    options.button_style &&
    /background-position/i.test(options.button_style.custom_css) &&
    !options.languages.some(lang => lang.custom_flag);

  // Class names for switcher container
  const dynamicClasses = clsx({
    open,
    closed: !open,
    "wg-drop": is_dropdown,
    "wg-list": !is_dropdown,
    "weg-openup": opensUpward && open,
    "weg-openleft": opensLeftward && open,
  });

  if (!responsiveVisible) {
    // @ts-ignore
    return <Fragment />;
  }

  return (
    <aside
      ref={switcherContainerRef}
      data-wg-notranslate
      style={
        open &&
        switcherContainerRef &&
        switcherContainerRef.current &&
        switcherContainerRef.current.offsetWidth < optionWidth && {
          width: optionWidth,
        }
      }
      onKeyDown={handleKeyDown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`weglot_switcher country-selector ${switcherName} ${dynamicClasses}`}
      aria-label={`Language selected: ${getLanguageName(language)}`}>
      {moveSelectedLangToFront && (
        //@ts-ignore
        <Option
          styleOpts={style}
          open={open}
          focusedLanguage={focusedLanguage}
          language={language}
          isSelected={true}
          onClick={toggleOpen}
          legacyFlags={legacyFlags}
          url="#"
        />
      )}
      <ul
        ref={otherLanguagesRef}
        role="listbox"
        id="weglot-listbox"
        aria-label="Language list"
        style={!open && style.is_dropdown && { display: "none" }}>
        {listLanguages.map(l => {
          return (
            //@ts-ignore
            <Option
              language={l}
              url={l === language ? "#" : urls[l]}
              onClick={switchLanguage}
              isSelected={l === language}
              focusedLanguage={focusedLanguage}
              key={`wg-${l}`}
              focusRef={focusedLanguageRef}
              styleOpts={style}
              legacyFlags={legacyFlags}
            />
          );
        })}
      </ul>
    </aside>
  );
};

export default Switcher;

export const createSwitcher = renderSwitcher(Switcher, switcherName);

import cookies from "common/helpers/cookies";

import { safeQuerySelectorAll } from "../../utils/helpers";
import options from "../options/options";
import { search } from "../translator";
import logger from "../../utils/logger";

export function updateSearchForms() {
  const { search_forms, search_parameter } = options;
  if (!search_parameter) {
    logger.warn("Search parameter name required for search translation.", {
      sendToDatadog: false,
    });
    return;
  }
  for (var searchForm of safeQuerySelectorAll(document, search_forms)) {
    searchForm.addEventListener("submit", e => {
      e.preventDefault();
      var original = e.target.elements[search_parameter].value;
      const sendForm = translated => {
        cookies.set({ name: "wg-search-form", value: original, options });
        searchForm.elements[search_parameter].value = translated;
        searchForm.submit();
      };
      search(original, sendForm);
    });
    let cookieValue;
    if (
      window.location.search.indexOf(`${search_parameter}=`) !== -1 &&
      searchForm.elements &&
      searchForm.elements[search_parameter] &&
      (cookieValue = cookies.get("wg-search-form"))
    ) {
      searchForm.elements[search_parameter].value = cookieValue;
    }
  }
  cookies.erase({ name: "wg-search-form", options });
}

import { h } from "preact";
import { useErrorBoundary } from "preact/hooks";
import { styled } from "preact-styled-components";

const Center = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
}));

function ErrorBoundary(props) {
  const { displayError = true, logger = () => {}, children } = props;
  const [error] = useErrorBoundary(error => logger(error));

  if (error && displayError) {
    return (
      <Center>
        <p>
          An error has occurred, we apologise for the inconvenience.
          <br />
          <br />
          We have been notified and will rectify the situation as soon as
          possible.
          <br />
          <br />
          Please try again later or contact support@weglot.com directly.
        </p>
      </Center>
    );
  }
  return children;
}

export default ErrorBoundary;

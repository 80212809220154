import { safeQuerySelectorAll, isSelectorValid } from "../../utils/helpers";

/*
Searches a node using the provided location selectors, and returns an object specifying
any valid target and sibling nodes found. Returns:
{
  error: String (error message to log to console) or Null (if valid),
  targetNode: Node (if target node found) or Null,
  siblingNode: Node (if sibling node found) or Null,
  defaultPosition: Boolean (Switcher added to default position)
}
The logic for placing a switcher is as follows:
  - 1. No target selector specified: Add to default position.
  - 2. Target provided but invalid / not present in DOM: Add nothing, log error.
  - 3. Valid target selector, no sibling specific: Add to start of first matching target.
  - 4. Valid target selector, invalid sibling selector: Add to start of first matching target.
  - 5. Target + sibling valid: Add switcher to target before sibling.
  - 6. Valid target + sibling selectors, but no target node contains sibling: Add nothing, log error.
*/
function getSwitcherPosition(location = {}, node) {
  const { target: targetSelector, sibling: siblingSelector } = location;
  if (!targetSelector) {
    // Case 1: Add to default position.
    return { defaultPosition: true };
  }
  const possibleTargetNodes = safeQuerySelectorAll(node, targetSelector);

  if (!possibleTargetNodes.length) {
    // Case 2: Target selector provided in options but no target nodes found.
    const isValidSelector = isSelectorValid(targetSelector);
    const error = isValidSelector
      ? "The provided target is not on this page."
      : "The provided target is not a valid CSS selector.";
    return { error };
  }

  const possibleSiblingNodes = safeQuerySelectorAll(node, siblingSelector);

  if (!siblingSelector || !possibleSiblingNodes.length) {
    // Cases 3,4: No sibling selector provided or no sibling nodes found in DOM.
    return {
      targetNode: possibleTargetNodes[0],
      siblingNode: null,
    };
  }

  const targetsArray = Array.from(possibleTargetNodes);
  const siblingsArray = Array.from(possibleSiblingNodes);
  let validTargetNode = null;
  const validSiblingNode = siblingsArray.find(s => {
    validTargetNode = targetsArray.find(t => s.parentNode === t);
    return !!validTargetNode;
  });
  if (validSiblingNode && validTargetNode) {
    // Case 5: Target + sibling valid. Add to target before sibling.
    return {
      targetNode: validTargetNode,
      siblingNode: validSiblingNode,
    };
  }
  // Case 6: No target node contains a sibling.
  return {
    error: "The provided sibling selector does not belong to target element.",
  };
}

export default getSwitcherPosition;

import cookies from "common/helpers/cookies";
import { getFromID } from "common/helpers/utils";

import {
  updateQueryStringParameter,
  isSearchBot,
  safeQuerySelector,
  inFrame,
} from "../../utils/helpers";
import logger from "../../utils/logger";
import options from "../options/options";
import { registerEvent } from "../../utils/events";
import { registerHook } from "../../utils/hooks";
import updateOnlyDisplay from "../addons/updateOnlyDisplay";
import getSlugs from "../query/slugs";
import { getPathSlugs } from "../../weglot/helpers/getLanguageURL";
import getCurrentLanguage from "../helpers/getCurrentLanguage";
import getLocaleRule from "../helpers/localeRules";
import storage from "../../utils/storage";

let cachedRoutes = {};
registerEvent(
  "onCurrentLocationChanged",
  () => {
    cachedRoutes = {};
  },
  true
);

function getSharedHost() {
  const { host = window.location.hostname } = options;
  return host.indexOf("www.") === 0 ? host.slice(3) : `.${host}`;
}

export function listenCartCookie() {
  const found = document.cookie.match(/(^cart=[^;]+|[\W]cart=[^;]+)/g);
  if (!found) {
    setTimeout(listenCartCookie, 100);
    return;
  }
  const cartIds = found.map(cookie =>
    decodeURIComponent(cookie.split("=").slice(1).join("="))
  );
  if (cartIds.length === 1 || cartIds[0] !== cartIds[1]) {
    cookies.set({
      name: "cart",
      value: cartIds[0],
      domain: getSharedHost(),
      options,
    });
  }
}

function convertPathLocale(langCode, path) {
  if (!options.is_connect || langCode === options.language_from) {
    return path;
  }
  if (cachedRoutes[path]) {
    return cachedRoutes[path];
  }
  let route;
  getSlugs(slugs => {
    const slugged =
      slugs && slugs[langCode]
        ? getPathSlugs(path, slugs[langCode].original)
        : path;
    route = options.subdirectory
      ? getLocaleRule().convertLocale(langCode, slugged, options.language_from)
      : slugged;
  });
  cachedRoutes[path] = route;
  return route;
}

function redirectToTranslatedCheckout(locale) {
  const universalCheckoutUrl = `/checkout?locale=${locale}${
    options.shopify_skip_shop_pay ? "&skip_shop_pay=true" : ""
  }`;
  fetch(universalCheckoutUrl)
    .then(r => {
      document.location.href = encodeURI(r.url);
    })
    .catch(() => {
      document.location.href = encodeURI(universalCheckoutUrl);
    });
}

function setLocales(langCode) {
  const lcode = langCode || getCurrentLanguage();
  const code = getCode(lcode);
  /* There are different valid cart/checkout attributes, (/cart, 
     /es/cart, /es-us/cart/), which all need a ?locale language param. 
     Use a broad selector and remove false positives via regex. */
  const selectors = [
    {
      name: "action",
      selector: [
        `form[method="post"][action*="/cart"]`,
        `form[method="post"][action*="/checkout"]`,
      ],
      testRegex: /\/(cart|checkout|)\/?(\?|$)/,
      event: "submit",
    },
    {
      name: "href",
      selector: [`a[href*="/checkout"]`, `a[href*="/cart/checkout"]`],
      testRegex: /\/(cart\/)?checkout\/?(\?|$)/,
      event: "click",
    },
  ];
  const queryParams = [
    {
      name: "locale",
      value: code,
    },
    ...(options.shopify_skip_shop_pay
      ? [
          {
            name: "skip_shop_pay",
            value: "true",
          },
        ]
      : []),
  ];

  selectors.forEach(({ name, selector, testRegex, event }) => {
    const elements = document.querySelectorAll(selector.join(","));
    for (const element of elements) {
      let attribute = element.getAttribute(name);
      const matches = testRegex.test(attribute);
      if (
        !matches ||
        queryParams.every(qp => attribute.includes(`${qp.name}=${qp.value}`))
      ) {
        // Nothing to update
        continue;
      }

      // Update query params in element attribute
      for (const qp of queryParams) {
        attribute = updateQueryStringParameter(attribute, qp.name, qp.value);
      }
      element.setAttribute(name, attribute);

      if (element.wgCheckoutListener) {
        element.removeEventListener(event, element.wgCheckoutListener);
      }
      if (
        lcode !== options.language_from &&
        options.fix_shopify_checkout_locale
      ) {
        element.wgCheckoutListener = e => {
          e.preventDefault();
          e.stopPropagation();
          if (options.is_connect && !options.subdirectory) {
            storage({ type: "cookie" }).setItem("wg_checkout_redirect", lcode);
            document.location.href = encodeURI(
              `${options.is_https ? "https:" : "http:"}//${options.host}`
            );
          } else {
            redirectToTranslatedCheckout(code);
          }
          return false;
        };
        element.addEventListener(event, element.wgCheckoutListener);
      }
    }
  });
}

export function getCode(lcode) {
  const specialCodes = {
    br: "pt-BR",
    no: "nb",
    pt: "pt-PT",
    ro: "ro-RO",
    fl: "fil",
    sa: "sr-lt",
    zh: "zh-CN",
    tw: "zh-TW",
  };
  if (specialCodes[lcode]) {
    return specialCodes[lcode];
  }
  return lcode.substr(0, 2);
}

function insertSignUpTag(targetLanguage) {
  const targetLang = targetLanguage || getCurrentLanguage();
  const form =
    document.getElementById("create_customer") ||
    document.querySelector(
      `form[action="${convertPathLocale(targetLang, "/account")}"]`
    ) ||
    (typeof options.customer_tag === "string" &&
      safeQuerySelector(document, options.customer_tag));
  if (!form) {
    return;
  }
  const element = document.getElementById("weglot-lang-form");
  if (element) {
    element.parentNode.removeChild(element);
  }
  const weglotTag = document.createElement("input");
  Object.assign(weglotTag, {
    type: "hidden",
    id: "weglot-lang-form",
    name: "customer[tags]",
    value: `#wg${targetLang}#wg`,
  });
  form.appendChild(weglotTag);
}

function addAttributesOnFetch() {
  // Override fetch method to send lang attributes (additional notes) with JS buy button
  const func = window.fetch;
  window.fetch = function () {
    if (arguments[0] === "/wallets/checkouts.json") {
      try {
        const json = JSON.parse(arguments[1].body);
        const lang = getCode(getCurrentLanguage());
        json.checkout.attributes = {};
        options.cart_attributes.forEach(
          attr => (json.checkout.attributes[attr] = lang)
        );
        arguments[1].body = JSON.stringify(json);
        // eslint-disable-next-line
      } catch (e) {}
    }
    return func.apply(window, arguments);
  };
}

function setCheckoutCookie(lcode) {
  const storeId = getShopifyStoreId();
  if (storeId) {
    cookies.set({
      name: "checkout_locale",
      value: getCode(lcode),
      path: storeId,
      options,
    });
  }
}

// Gets the ID of a Shopify Store
export function getShopifyStoreId() {
  const script = document.getElementById("shopify-features");
  if (!script) {
    return null;
  }
  const matches = script.textContent.match(/"shopId":(\d*)/);
  return matches ? matches[1] : null;
}

export function updateCartAttribute(target) {
  const targetLang = target || getCurrentLanguage();
  if (options.visual_editor || inFrame()) {
    return;
  }
  const {
    cart_attributes,
    is_connect,
    original_shopify_checkout,
    subdirectory,
    language_from,
  } = options;
  // 1 - send ajax information to update.js
  const cartToken =
    storage({ type: "cookie" }).getItem("cart") &&
    decodeURIComponent(storage({ type: "cookie" }).getItem("cart"));
  const cartUpdateToken =
    storage({ type: "session" }).getItem("wg-cart-update-token") &&
    decodeURIComponent(
      storage({ type: "session" }).getItem("wg-cart-update-token")
    );
  const cartUpdateLang = storage({ type: "session" }).getItem(
    "wg-cart-update-lang"
  );
  // Update cart if either the language has changed or the cart has changed
  if (cartUpdateLang !== getCode(targetLang) || cartToken !== cartUpdateToken) {
    const data = cart_attributes
      .map(
        attributeName => `attributes[${attributeName}]=${getCode(targetLang)}`
      )
      .join("&");
    const updateCart = fetch("/cart/update.js", {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      credentials: "same-origin",
    });

    if (
      original_shopify_checkout !== false &&
      is_connect &&
      !subdirectory &&
      language_from === getCurrentLanguage()
    ) {
      updateCart
        .then(res => res.json())
        .then(({ token }) =>
          cookies.set({
            name: "cart",
            value: token,
            domain: getSharedHost(),
            options,
          })
        );
    }

    storage({ type: "session" }).setItem("wg-cart-update-token", cartToken);
    storage({ type: "session" }).setItem(
      "wg-cart-update-lang",
      getCode(targetLang)
    );
  }

  // 2 - update direct link to checkout shopify format
  const potentialLinks = document.querySelectorAll('a[href*="/cart/"]');
  const langQuery = `attributes[lang]=${targetLang}`;
  for (const link of potentialLinks) {
    let href = link.getAttribute("href");
    if (!href) {
      continue;
    }
    const format = href.match(/\/cart\/\d+:\d+(\?)?/);
    if (format) {
      href = href.replace(/&?attributes\[lang\]=([a-zA-Z-]+)/g, "");
      link.setAttribute("href", `${href}${format[1] ? "&" : "?"}${langQuery}`);
    }
  }
}

export function setCheckoutProps(targetLang) {
  if (options.language_from === targetLang) {
    // @Todo: This will need to be updated if ever we want to allow users
    // to add an additional locale code on subdirectory for original lang.
    return;
  }
  if (window["Shopify"]) {
    window["Shopify"].locale = targetLang;
  }
  if (!isSearchBot() && options.order_tag) {
    updateCartAttribute(targetLang);
  }
  setLocales(targetLang);
  setCheckoutCookie(targetLang);
  const blocks = document.querySelectorAll("[data-wg-only-display]");
  if (blocks.length) {
    updateOnlyDisplay(blocks, targetLang);
  }
  if (options.customer_tag) {
    insertSignUpTag(targetLang);
  }
}

function hasPreviewScript() {
  for (let script of document.scripts) {
    if (script.src.indexOf("preview_bar_injector") !== -1) {
      return true;
    }
  }
  return false;
}

export default function () {
  registerHook("onWeglotSetup", () => {
    if (
      options.original_shopify_checkout !== false &&
      options.is_connect &&
      !options.subdirectory &&
      options.language_from === getCurrentLanguage()
    ) {
      listenCartCookie();
    }
  });

  registerEvent(
    "initialized",
    () => {
      const checkoutLanguage = storage({ type: "cookie" }).getItem(
        "wg_checkout_redirect"
      );
      if (checkoutLanguage) {
        storage({ type: "cookie" }).removeItem("wg_checkout_redirect");
        storage({ type: "cookie" }).setItem(
          "wg_checkout_language",
          checkoutLanguage
        );
        redirectToTranslatedCheckout(checkoutLanguage);
      }

      if (window["langify"]) {
        // eslint-disable-next-line
        logger.log("%c Please, uninstall langify to properly use Weglot", {
          sendToDatadog: false,
        });
      }
      if (
        !isSearchBot() &&
        options.order_tag &&
        (!options.is_connect || options.language_from === getCurrentLanguage())
      ) {
        updateCartAttribute();
      }
      setLocales();
      const blocks = document.querySelectorAll("[data-wg-only-display]");
      if (blocks.length) {
        updateOnlyDisplay(blocks);
      }
      if (options.customer_tag) {
        insertSignUpTag();
      }
      if (document.getElementsByClassName("shopify-payment-button").length) {
        addAttributesOnFetch();
      }
    },
    true
  );

  registerHook("onConnectPageLoad", targetLang => setCheckoutProps(targetLang));
  registerHook("onPageLanguageSet", targetLang => setCheckoutProps(targetLang));

  registerHook("onDynamicDetected", () => {
    setLocales(getCurrentLanguage());
  });

  registerHook(
    "startWhen",
    () =>
      getFromID("admin-bar-iframe") ||
      getFromID("preview-bar-iframe") ||
      options.private_mode ||
      hasPreviewScript()
  );

  return {};
}

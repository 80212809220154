import { useTemplateVersions } from "../constants";
import { safeQuerySelector } from "./utils";

export default function addSwitcherTemplateScript(
  { name, hash = null },
  switcherEditor
) {
  // Check if switcher script has already been injected into the page. If so, nothing to do.
  if (
    safeQuerySelector(
      document.documentElement,
      `script#weglot-switcher-${name}`
    )
  ) {
    // Script already added to page; don't add it twice.
    return false;
  }
  const filename =
    useTemplateVersions && !switcherEditor && hash ? `${name}.${hash}` : name;
  const head =
    document.getElementsByTagName("head")[0] || document.documentElement;
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `https://CDN_ASSETS_HOST/switchers/${filename}.min.js`;
  script.id = `weglot-switcher-${name}`;
  head.insertBefore(script, head.firstChild);
  return true;
}

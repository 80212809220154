import { resize, bound } from "../../shared/utils";
function getCss({ style: { size_scale } }) {
  const scale = initial => resize(initial, size_scale);
  return [
    {
      selector: ".wg-drop ul",
      declarations: {
        top: `${scale(38)}px`,
        bottom: "auto",
      },
    },
    {
      selector: ".wg-drop.weg-openup ul",
      declarations: {
        bottom: `${scale(38)}px`,
        top: "auto",
      },
    },
    {
      selector: " a",
      declarations: {
        fontSize: `${scale(13)}px`,
      },
    },
    {
      selector: ".wg-drop a img.wg-flag",
      declarations: {
        height: `${scale(30)}px`,
      },
    },
    {
      selector: ".wg-drop .wg-li.wgcurrent",
      declarations: {
        height: `${scale(38)}px`,
        display: "flex",
        alignItems: "center",
      },
    },
    {
      selector: ".wg-drop a",
      declarations: {
        height: `${scale(38)}px`,
      },
    },
    {
      selector: " .wgcurrent:after",
      declarations: {
        height: `${scale(38)}px`,
        backgroundSize: `${scale(9)}px`,
      },
    },
    {
      selector: ".wg-drop .wgcurrent a",
      declarations: {
        paddingRight: `${bound(22, scale(40), 40)}px`,
        paddingLeft: `${bound(5, scale(10), 10)}px`,
      },
    },
  ];
}

export default getCss;

import options from "./options";
import { definitions } from "./index";
import {
  isValidTextNode,
  getElementsFromSelectors,
  removeAttributes,
  getAttributes,
  isValidMergeNode,
  safeQuerySelectorAll,
  safeMatches,
  canMergeNode,
  isNoTranslate,
  safeClosest,
} from "./helpers";

const mergedNodes = new WeakMap();

/**
 * Get all text nodes with content or attribute to translate.
 * @returns {object[]}
 */
export default function getTextNodes(node) {
  if (!node) {
    return [];
  }
  const targetElement = node.querySelectorAll ? node : node.parentNode;
  if (!targetElement) {
    return [];
  }

  setNoTranslate(targetElement);

  if (!options.whitelist || !options.whitelist.length) {
    return [].concat(getTitle(targetElement), getTexts(targetElement));
  }

  const whitelist = options.whitelist.map(w => w.value).join(",");
  // target is in a whitelisted element
  if (safeClosest(targetElement, whitelist)) {
    return getTexts(targetElement);
  }
  // or search whitelisted elements in the target
  const nodes = [];
  const nodeList = safeQuerySelectorAll(targetElement, whitelist);
  for (let whiteNode of nodeList) {
    [].push.apply(nodes, getTexts(whiteNode));
  }
  return nodes;
}

function getTitle(parent) {
  const title = document.getElementsByTagName("title")[0];
  // Only add title on first and main request
  if (
    parent !== document.documentElement ||
    !document.title ||
    !title ||
    isNoTranslate(title)
  ) {
    return [];
  }
  // we don't use document.title as words because browser is formatting it
  // we need to get the same as back-end parsers, Connect or WordPress
  return [
    {
      element: title.firstChild,
      type: 9,
      words: title.textContent,
      properties: {},
    },
  ];
}

function getTexts(targetElement) {
  return [].concat(
    getElementsWithAttributes(targetElement),
    getElementsWithText(targetElement)
  );
}

function walkerFilter(node) {
  if (!isValidTextNode(node) || isNoTranslate(node)) {
    return NodeFilter.FILTER_REJECT;
  }
  return NodeFilter.FILTER_ACCEPT;
}
// Due from createTreeWalker inconsistency
walkerFilter.acceptNode = walkerFilter;

/**
 * Get all nodes with text
 * -> Merge inline child nodes (<b>, <i>...)
 * -> Replace attributes we don't want translate (class, id...)
 * @returns {object[]}
 * @private
 */
function getElementsWithText(targetElement) {
  const nodes = [],
    mergeVersion = options.translation_engine >= 2,
    walker = document.createTreeWalker(targetElement, 4, walkerFilter, false);
  let node;
  while ((node = walker.nextNode())) {
    const parser =
      mergeVersion &&
      (canMergeNode(node.parentNode) || node.parentNode.childNodes.length > 1)
        ? parseMergedNodes
        : parseNodes;
    const parsed = parser(node, walker);
    if (parsed) {
      nodes.push(parsed);
    }
  }
  return nodes;
}

function parseMergedNodes(node, walker) {
  // Search if parent or itself was already merged, so get it
  const closestResolved = closeResolved(node);
  if (closestResolved && mergedNodes.has(closestResolved)) {
    const [element, words, properties] = mergedNodes.get(closestResolved);
    return { element, words, type: 1, properties };
  }
  // If not merged or saved yet, go
  const mergedNode = mergeWalkNode(node, walker);
  if (!mergedNode) {
    return;
  }
  const [element, words, properties] = mergedNode;
  if (isEmpty(words)) {
    return;
  }
  mergedNodes.set(element, mergedNode);
  return { element, words, type: 1, properties };
}

function parseNodes(node) {
  const words = node.textContent;
  if (isEmpty(words)) {
    return;
  }
  return { element: node, words, type: 1, properties: {} };
}

/**
 * Get all nodes with specifics attributes
 * -> These attributes are specified in definition set
 * -> These nodes don't include text content: img, input, meta...
 * @returns {object[]}
 */
function getElementsWithAttributes(targetElement) {
  const nodes = [];
  definitions.forEach(definition => {
    const { attribute, type, selectors } = definition;
    const els = getElementsFromSelectors(targetElement, selectors);
    for (let element of els) {
      if (isNoTranslate(element)) {
        continue;
      }
      const words = attribute.get(element);
      if (isEmpty(words)) {
        continue;
      }
      nodes.push({
        element,
        words,
        type,
        attrSetter: attribute.set,
        attrName: attribute.name,
      });
    }
  });
  return nodes;
}

function addNoTranslate(element, excludedSelectors) {
  element.wgNoTranslate = options.private_mode
    ? `Excluded by selector: ${excludedSelectors.find(s => safeMatches(element, s))}`
    : true;
}

function removeNoTranslate(element, joinedExcludedSelector) {
  if (
    "wgNoTranslate" in element &&
    element.wgNoTranslate &&
    !safeMatches(element, joinedExcludedSelector)
  ) {
    element.wgNoTranslate = false;
  }

  if (element.children) {
    for (const child of element.children) {
      removeNoTranslate(child, joinedExcludedSelector);
    }
  }
}

export function setNoTranslate(element) {
  const { excluded_blocks } = options;

  if (!excluded_blocks || !excluded_blocks.length) {
    element.wgNoTranslate = false;
    return;
  }

  const selectors = excluded_blocks.map(b => b.value);
  const joinedSelector = selectors.join(",");

  if (safeMatches(element, joinedSelector)) {
    addNoTranslate(element, selectors);
  } else {
    element.wgNoTranslate = false;
  }

  for (const node of safeQuerySelectorAll(element, joinedSelector)) {
    addNoTranslate(node, selectors);
  }

  removeNoTranslate(element, joinedSelector);
}

function mergeWalkNode(node, walker) {
  const properties = [];
  let newNode = node;

  // Take parent node if there is no block tag inside, as top we can
  while (isValidMergeNode(node.parentNode)) {
    node = node.parentNode;
    // We want to update a "newNode" separatly if textContent change,
    // then, we can continue to search parents node, maybe one will
    // change content again, if not, we will revert with the deepest node
    if (newNode.textContent.trim() !== node.textContent.trim()) {
      newNode = node;
    }
  }

  // Compare textContent updated when changed with the last parent got,
  // revert if no difference to avoid nested tags (<u><i><b>Hi</b></i></u>)
  if (newNode.textContent.trim() === node.textContent.trim()) {
    node = newNode;
  }

  for (;;) {
    if (!walker.nextNode()) {
      break;
    }
    if (!node.contains || !node.contains(walker.currentNode)) {
      walker.previousNode();
      break;
    }
  }

  // Clone parent
  const clone =
    node instanceof ShadowRoot && !node.clonable
      ? cloneShadow(node)
      : node.cloneNode(true);
  if (options.translation_engine > 2) {
    // Copy attributes and childs
    recursiveOnChilds(node, child => {
      if (child.nodeType === 1) {
        const attributes = getAttributes(child);
        properties.push({ attributes, child });
      }
    });
    let i = 1;
    // Replace all attributes by wg-{n}=""
    recursiveOnChilds(clone, child => {
      if (child.nodeType === 1) {
        removeAttributes(child);
        child.setAttribute(`wg-${i++}`, "");
      }
    });
  }

  if (node) {
    node.wgResolved = true;
    const htmlContent = clone.innerHTML || clone.textContent || "";
    return [node, htmlContent.replace(/<!--[^>]*-->/g, ""), properties];
  }
}

// Might be less complex & more efficient
function recursiveOnChilds(node, fn) {
  if (!node.childNodes) {
    return;
  }
  for (let child of node.childNodes) {
    if (!child) {
      return;
    }
    fn(child);
    recursiveOnChilds(child, fn);
  }
}

function isEmpty(string) {
  return !string || !string.trim() || !isNaN(string) || string === "\u200b";
}

function closeResolved(node) {
  if (node.wgResolved) {
    return false;
  }
  let el = node;
  do {
    if (el.wgResolved) return el;
    el = el.parentElement || el.parentNode;
  } while (el !== null && el.nodeType === 1);
  return false;
}

function cloneShadow(shadow) {
  const frag = document.createDocumentFragment();
  shadow.childNodes.forEach(node => frag.appendChild(node.cloneNode(true)));
  return frag;
}

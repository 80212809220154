// Returns a logger object with functions for info, warn and error.
// Each function accepts either string, object or exception as its
// first argument. If an exception is provided, only the message is
// passed to the console, but the entire error is sent to Datadog.
// For local development we don't send anything to Datadog.

const ddBody = {
  ddtags: "env:DATADOG_ENV",
  clientToken: "DATADOG_CLIENT_TOKEN",
  site: "DATADOG_SITE",
  version: "PACKAGE_VERSION",
};

const ddParams = {
  "dd-api-key": "DATADOG_CLIENT_TOKEN",
  ddsource: "browser",
};

const ddContext = {
  env: "DATADOG_ENV",
};

function logToDD(service, logs, method) {
  if (typeof logs === "string") {
    // Convert string logs to object.
    logs = { message: logs };
  }
  const data = {
    ...logs,
    service,
    status: method,
    ...(window.location && { view: { url: window.location.href } }),
    // Copy error props explicitly since { ...Error } = {}
    ...(logs.message && { message: logs.message }),
    ...(logs.stack && { stack: logs.stack }),
    ...(logs.status && { logStatus: logs.status }), // Don't override DD status.
    ...ddBody,
  };
  if (window.Weglot && window.Weglot.options) {
    data.projectInfo = [
      "host",
      "api_key",
      "url_type",
      "technology_name",
      "technology_id",
      "is_connect",
      "auto_switch",
    ].reduce(
      (all, cur) => ({
        ...all,
        [cur]: window.Weglot.options[cur],
      }),
      {}
    );
  }

  const urlSearch = Object.keys(ddParams)
    .map(k => `${k}=${ddParams[k]}`)
    .join("&");

  return fetch(`DATADOG_ENDPOINT?${urlSearch}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
}

export default function createLogger({ service }) {
  function log(logs, method, options) {
    const {
      sendToConsole = true,
      consoleOverride,
      sendToDatadog = true,
    } = options;
    if (sendToDatadog && ddContext.env !== "dev") {
      logToDD(service, logs, method);
    }
    if (sendToConsole) {
      const consoleMessage = consoleOverride || logs;
      const consoleMethod = ["notice", "info"].includes(method)
        ? "log"
        : method;
      console[consoleMethod]("[Weglot]", consoleMessage); // eslint-disable-line no-console
    }
  }

  const loggers =
    method =>
    (message, options = {}) => {
      return log(message, method, options);
    };

  return {
    log: loggers("info"),
    info: loggers("info"),
    notice: loggers("notice"),
    warn: loggers("warn"),
    error: loggers("error"),
  };
}

//@ts-ignore
import technologyRules from "@weglot/technology-rules/dist/urls.json";

import URL from "common/helpers/url";

import logger from "../../utils/logger";
import options from "../options/options";
import { technologies } from "../constants";
import { definitionChecker, technologyIdChecker } from "../technology/rules";
import getCurrentLocation from "../helpers/getLocation";

/**
 * Get current URL, without full number slugs & applied custom rules
 * @returns {string}
 */
export default function getFormattedCurrentUrl() {
  const url = currentUrl();
  if (!options.disable_remove_numeric_slugs) {
    url.pathname = removeNumberSlugs(url.pathname);
  }
  for (const { value } of getUrlRules()) {
    try {
      for (const { original, formatted } of value) {
        const replaced = url.pathname.replace(new RegExp(original), formatted);
        if (replaced !== url.pathname) {
          url.pathname = replaced;
          return url.toString();
        }
      }
    } catch (e) {
      logger.warn(e, {
        consoleOverride: `Invalid URL regex, ${e.stack}`,
      });
    }
  }
  return url.toString();
}

/**
 * @returns {URL}
 * @private
 */
function currentUrl() {
  if (options.visual_editor) {
    return new URL(getCurrentLocation().url);
  }
  const { technology_name, injectedData } = options;
  // Wix has a lot of dynamics navigation, don't always send same canonical link
  if (technology_name === technologies.wix.name) {
    return new URL(window.location.href);
  }
  if (injectedData && injectedData.originalCanonicalUrl) {
    try {
      return new URL(injectedData.originalCanonicalUrl);
      } catch (e) {} // eslint-disable-line
  }
  const canonical = document.querySelector("link[rel='canonical'][href]");
  if (canonical) {
    try {
      return new URL(canonical["href"]);
      } catch (e) {} // eslint-disable-line
  }
  return new URL(window.location.href);
}

function removeNumberSlugs(pathname) {
  return pathname
    .split("/")
    .filter(slug => !slug || isNaN(Number(slug)))
    .join("/");
}

function getUrlRules() {
  return []
    .concat(
      ...technologyRules
        .filter(rule => technologyIdChecker(rule.technology_id))
        .map(({ urls }) => urls),
      (options.definitions && options.definitions.urls) || []
    )
    .filter(({ condition }) => definitionChecker(condition));
}

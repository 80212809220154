import { hasHTML } from "./helpers";
import { definitions } from "./index";
import logger from "./logger";
import options from "./options";

export default function setTextWords(elements, targetLang) {
  for (const element of elements) {
    const { content } = element.weglot;
    if (!content || !element.isConnected) {
      continue;
    }
    for (const details of content) {
      const { original, properties, attrSetter, translations } = details;
      const translation = translations[targetLang] || original;
      if (
        targetLang !== options.language_from &&
        (!translations[targetLang] || original === translations[targetLang])
      ) {
        continue;
      }
      if (properties) {
        element.weglot.setted = true;
        setHTMLTranslation(
          element,
          translation,
          properties,
          original,
          elements
        );
      }
      if (attrSetter) {
        element.weglot.setted = true;
        attrSetter(element, translation, original);
      }
    }
    element.wgResolved = false;
  }
}

export function setHTMLTranslation(
  element,
  translation,
  properties,
  original,
  elements
) {
  // If it's an HTML Element, we replace innerHTML
  if (element.nodeType === 1) {
    const frag = substituteProperties(translation, element, properties);
    element.innerHTML = "";
    element.appendChild(frag);
    return;
  }
  // If no HTML in translation OR HTML present in original, set it as text
  if (!hasHTML(translation) || hasHTML(original)) {
    element.textContent = translation;
    return;
  }
  if (!element.parentNode) {
    logger.warn(
      "Unable to translate some words, please contact support@weglot.com."
    );
    logger.warn(element, { sendToDatadog: false });
    return;
  }
  // Tricky part: there is HTML in translation but not in original, so it's a Text Node.
  // If parent have only one child
  if (element.parentNode.childNodes.length === 1) {
    // Transfer weglot object to parent
    element.parentNode.weglot = element.weglot;
    // Add parent to elements list, so it'll be handle at the end of setTextWords loop
    if (elements) {
      elements.push(element.parentNode);
    } else {
      setHTMLTranslation(element.parentNode, translation, properties, original);
    }
    // element will be disconnected from DOM when it'll be translated
    return;
  }

  // If parent has several childs

  // Check if we are already in a translation wrapper
  const closestWrapper =
    (element.closest && element.closest("[data-wg-translation-wrapper]")) ||
    element.parentNode.closest("[data-wg-translation-wrapper]");
  if (closestWrapper && closestWrapper.innerHTML === translation) {
    // Don't do anything if the closest containing wrapper contains the exact same translation we are trying to put here
    // Context: can happen when the translation contains html AND contains the original text
    return;
  }

  // - replace element with wrapper
  const span = document.createElement("span");
  span.dataset.wgTranslationWrapper = "";
  // - transfer weglot object to this wrapper
  span.weglot = element.weglot;
  element.parentNode.replaceChild(span, element);

  // - add this wrapper to elements list, it'll be handle at the end of setTextWords loop
  if (elements) {
    elements.push(span);
  } else {
    setHTMLTranslation(element.parentNode, translation, properties, original);
  }
}

export function setAttributeTranslation(element, translation, words, type) {
  const definition = definitions.find(
    def => def.type === type && def.selectors.some(sel => element.matches(sel))
  );
  if (definition && definition.attribute) {
    definition.attribute.set(element, translation, words);
  }
}

function substituteProperties(translation, node, properties) {
  // Create virtual HTML document from translated string
  const dom = document.createElement("div");
  dom.innerHTML = translation; // eslint-disable-line
  return replaceChild(node, dom, properties);
}

function replaceChild(original, translation, attributes) {
  const fragment = document.createDocumentFragment();
  if (original.nodeType !== 1) {
    fragment.appendChild(translation);
    return fragment;
  }
  const length = translation.childNodes.length;
  for (let i = 0; i < length; i++) {
    const traChild = translation.firstChild;
    let attributeNumber;
    if ((attributeNumber = getWgAttribute(traChild))) {
      const properties = attributes[attributeNumber - 1];
      if (!properties) {
        // There are too many tags in the translation
        continue;
      }
      // Get original element to conserve all events and properties
      const originalNode = properties.used
        ? properties.child.cloneNode(true)
        : properties.child;
      // Recursively replace child of translated element
      const content = replaceChild(originalNode, traChild, attributes);
      if (content.contains(originalNode)) {
        logger.warn(
          `There is an HTML error in the translation of: ${original.innerHTML.toString()}`,
          { sendToDatadog: false }
        );
        return fragment;
      }
      originalNode.innerHTML = "";
      originalNode.appendChild(content);
      fragment.appendChild(originalNode);
      // Delete child
      document.createDocumentFragment().appendChild(traChild);
      properties.used = true;
    } else {
      fragment.appendChild(traChild);
    }
  }
  return fragment;
}

function getWgAttribute(node) {
  if (!node || node.nodeType !== 1 || !node.attributes || !node.attributes[0]) {
    return;
  }
  const number = parseInt(node.attributes[0].name.split("wg-")[1]);
  return isNaN(number) ? undefined : number;
}

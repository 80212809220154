import storage from "../../utils/storage";
import getAvailableLanguages from "./getAvailableLanguages";
import { langStorageKey } from "../constants";

export function getStoredLang() {
  const storedLang = storage().getItem(langStorageKey);
  if (storedLang && getAvailableLanguages().includes(storedLang)) {
    return storedLang;
  }
}

export const setStoredLang = lang =>
  lang && storage().setItem(langStorageKey, lang);

export const removeStoredLang = () => storage().removeItem(langStorageKey);

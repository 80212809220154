const options = {
  // Classic tags options
  excluded_blocks: [],
  media_enabled: false,
  external_enabled: false,
  extra_definitions: [],
  // Same in weglot-js lib
  translation_engine: 2,
  noTranslateAttribute: "data-wg-notranslate",
  // Nodes to merge, variable with translation engine
  mergeNodes: [],
};

export function setOptions(userOptions) {
  Object.assign(options, userOptions);
}

export default options;

import { h } from "preact";

import { getLanguageFlag, getLanguageName, resize } from "../../shared/utils";

const FLAG_SIZES = {
  rectangle_mat: { width: 30, height: 20 },
  shiny: { width: 30, height: 20 },
  circle: { width: 24, height: 24 },
  square: { width: 24, height: 24 },
};

const Flag = props => {
  const { language, flagType = "circle", size_scale } = props;
  const code = getLanguageFlag(language);
  const { width, height } = FLAG_SIZES[flagType] || {};
  if (!code) {
    return;
  }
  const src =
    code.indexOf("http") > -1
      ? code
      : `https://CDN_ASSETS_HOST/flags/${flagType}/${code}.svg`;

  return (
    <img
      src={src}
      width={resize(width, size_scale)}
      height={resize(height, size_scale)}
      className="wg-flag"
      role="presentation"
      alt={`${getLanguageName(language)} flag`}
    />
  );
};

export default Flag;

import URL from "common/helpers/url";

import options from "../options/options";
import getSlugs from "../query/slugs";
import getCurrentLanguage from "./getCurrentLanguage";
import getCurrentLocation from "./getLocation";
import getLocaleRule from "./localeRules";
import { getHreflangLink } from "common/helpers/utils.js";
import logger from "../../utils/logger.js";
import getInternalLanguageCode from "./getInternalLanguageCode.js";

const cache = {};

function getHostnameByCode(code) {
  if (options.subdirectory) {
    return false;
  }

  const { language_from, host, languages } = options;
  if (code === language_from) {
    return host;
  }

  const { connect_host_destination } =
    languages.find(l => l.custom_code === code || l.language_to === code) || {};
  return connect_host_destination && connect_host_destination.host;
}

export function getPathSlugs(pathname, slugs) {
  return pathname
    .split("/")
    .map(slug => slugs[decodeURIComponent(slug)] || slug)
    .join("/");
}

function getPath(allSlugs, pathname, currentLanguage, targetLanguage) {
  if (!Object.keys(allSlugs).length) {
    return pathname;
  }

  if (!cache[pathname]) {
    cache[pathname] = {};
  }

  // Get the original pathname once
  if (!cache[pathname].originalPath) {
    // If we are on original language, the original pathname is the current one
    if (
      currentLanguage === options.language_from ||
      !allSlugs[currentLanguage]
    ) {
      cache[pathname].originalPath = pathname;
    } else {
      // Otherwise, get original pathname with slugs
      const slugs = allSlugs[currentLanguage].translated;
      cache[pathname].originalPath = getPathSlugs(pathname, slugs);
    }
  }

  if (targetLanguage === options.language_from) {
    return cache[pathname].originalPath;
  }

  if (!allSlugs[targetLanguage] || !allSlugs[targetLanguage].original) {
    return pathname;
  }
  // Translate the original pathname into the language we want
  return getPathSlugs(
    cache[pathname].originalPath,
    allSlugs[targetLanguage].original
  );
}

function addAutoSwitchParam(langCode, url) {
  // If we are on tld, include no_redirect the language_from URL.
  const isAutoSwitch = options.auto_switch || options.geo_auto_switch;
  if (!isAutoSwitch || (!options.is_tld && !options.rendered)) {
    return;
  }
  if (langCode === options.language_from) {
    url.searchParams.set("no_redirect", "true");
  } else {
    url.searchParams.delete("no_redirect");
  }
}

export function getURL(langCode, slugs, useUrlString = null) {
  let url;
  if (useUrlString) {
    try {
      url = new URL(useUrlString, window.location.origin);
    } catch (_) {
      return useUrlString;
    }
  } else {
    url = new URL(getCurrentLocation().url);
    if (options.visual_editor && url.searchParams.has("url")) {
      url = new URL(url.searchParams.get("url"));
    }

    if (url.searchParams.has("lang")) {
      url.searchParams.delete("lang");
    }
    addAutoSwitchParam(langCode, url);
  }

  const hostname = getHostnameByCode(langCode);
  if (hostname) {
    url.hostname = hostname;
  }

  url.pathname = getPath(slugs, url.pathname, getCurrentLanguage(), langCode);
  if (options.subdirectory && langCode) {
    url.pathname = getLocaleRule().convertLocale(langCode, url.pathname);
  }
  return url.toString();
}

export default function getLanguageURL(language, callback) {
  if (options.technology_name === "WordPress" && !options.is_connect) {
    if (options.injectedData && options.injectedData.switcher_links) {
      const languageCode = getInternalLanguageCode(language);
      return callback(options.injectedData.switcher_links[languageCode]);
    }
    // Fallback: No switcher_links in options: Use hreflang elements in DOM
    const hrefLangLink = getHreflangLink(language);
    if (hrefLangLink) {
      return callback(hrefLangLink);
    }
    logger.error("No switcher_links or hreflang elements found.", {
      sendToDatadog: false,
    });
    return callback("#");
  }

  if (!options.is_connect || !language) {
    return callback("#");
  }

  const { dynamicPushState, injectedData: { allLanguageUrls = {} } = {} } =
    options;
  if (!dynamicPushState && allLanguageUrls && allLanguageUrls[language]) {
    const url = new URL(allLanguageUrls[language]);
    addAutoSwitchParam(language, url);
    return callback(url.toString());
  }
  getSlugs(slugs => callback(getURL(language, slugs)));
}

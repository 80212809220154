const cookies = {};
let enabledCookies;

try {
  document.cookie;
  enabledCookies = true;
} catch (e) {
  enabledCookies = false;
}

cookies.set = function ({
  name,
  value,
  domain = null,
  path = null,
  expires = null,
  partitioned = null,
  options,
}) {
  if (!enabledCookies || !name) {
    return;
  }
  const { host, is_connect, subdirectory } = options;
  name = name
    .replace(/[^+#$&^`|]/g, encodeURIComponent)
    .replace("(", "%28")
    .replace(")", "%29");
  value = value.toString().replace(/[^+#$&/:<-[\]-}]/g, encodeURIComponent);
  if (!domain && is_connect && host) {
    // Host not defined yet when isPrivateMode called
    domain = subdirectory ? host : host.split("www.").pop();
  }
  const setDomain = domain ? `;domain=${domain}` : "";
  const setExpires = expires ? `;expires=${expires}` : "";
  const setPath = `;path=/${path || ""}`;
  const setPartitioned = partitioned ? `;Partitioned` : "";
  document.cookie = `${name}=${value}${setDomain}${setPath}${setExpires}${setPartitioned};SameSite=None;Secure`;
};

cookies.get = function (name) {
  if (!enabledCookies) {
    return null;
  }
  const cookies = document.cookie.split(";");
  while (cookies.length) {
    const cookie = cookies.pop();
    let separatorIndex = cookie.indexOf("=");
    separatorIndex = separatorIndex < 0 ? cookie.length : separatorIndex;
    const cookie_name = decodeURIComponent(
      cookie.slice(0, separatorIndex).replace(/^\s+/, "")
    );
    if (cookie_name === name) {
      return decodeURIComponent(cookie.slice(separatorIndex + 1));
    }
  }
  return null;
};

cookies.erase = function ({ name, domain = null, path = null, options }) {
  cookies.set({
    name,
    value: "",
    domain,
    path,
    expires: "Thu, 01 Jan 1970 00:00:00 GMT",
    options,
  });
};

export default cookies;

import { h } from "preact";

import { getLanguageName, FLAGS } from "../../shared/utils";

import Flag from "./Flag";

const Option = ({
  styleOpts,
  language,
  onClick,
  legacyFlags,
  open = false,
  url,
  focusedLanguage,
  isSelected = false,
  focusRef = null,
}) => {
  const {
    with_name = true,
    full_name = true,
    with_flags,
    size_scale,
    flag_type,
  } = styleOpts;
  const isFocused = focusedLanguage ? language === focusedLanguage : false;
  const name = full_name ? getLanguageName(language) : language.toUpperCase();
  const WrapperName = isSelected ? "div" : "li";

  const flagIndex = FLAGS.indexOf(flag_type || "rectangle_mat");
  const legacyFlagClass = legacyFlags ? ` flag-${flagIndex} legacy` : "";
  const flagClass = with_flags ? ` wg-flags${legacyFlagClass}` : "";
  const focusedClass = isFocused && !isSelected ? " focus" : "";
  const selectedClass = isSelected ? " wgcurrent" : "";

  function handleClick(e, l) {
    e.preventDefault();
    onClick(l);
  }

  return (
    <WrapperName
      data-l={language}
      onClick={e => handleClick(e, language)}
      onKeyDown={e => {
        if (isSelected && e.key === "Enter") {
          handleClick(e, language);
        }
      }}
      tabIndex={0}
      className={`wg-li ${language}${selectedClass}${flagClass}${focusedClass}`}
      {...(isSelected
        ? {
            role: "combobox",
            "aria-activedescendant": focusedLanguage
              ? `weglot-language-${focusedLanguage}`
              : "",
            "aria-label": "Language",
            "aria-expanded": open,
            "aria-controls": "weglot-listbox",
          }
        : { role: "none", id: `wg-${language}` })}>
      <a
        {...(isSelected ? { target: "_self" } : { role: "option" })}
        href={url}
        {...(!with_name && { "aria-label": name })}
        {...(isFocused && !isSelected && { ref: focusRef })}
        id={`weglot-language-${language}`}
        tabIndex={-1}>
        {with_flags && !legacyFlags && (
          <Flag
            language={language}
            flagType={flag_type}
            size_scale={size_scale}
          />
        )}
        {with_name && name}
      </a>
    </WrapperName>
  );
};

export default Option;

import logger from "../../utils/logger";
import options from "../options/options";

/**
 * Send POST request to Weglot API with @param body content
 * @param {object} body
 * @returns {Promise<object>}
 * @private
 */
export default function request(body) {
  const version = (options.versions && options.versions.translation) || 1;
  const params = [`api_key=${options.api_key}`, `v=${version}`];
  const url = `https://${
    options.bypass_cdn_api ? "SOURCE_API_HOST" : "CDN_API_HOST"
  }/translate?${params.join("&")}`;
  const headers = options.previewHash ? { "weglot-source": "preview" } : {};

  return fetch(url, {
    method: "POST",
    body: encodePayload(JSON.stringify(body)),
    headers,
  })
    .then(checkStatus)
    .then(data => data.json())
    .then(json => {
      if (!json || !json.to_words) {
        logger.warn(json);
        throw Error("An error occurred, please try again later");
      }
      return json;
    });
}

/**
 * Encode JSON body instead of use Content-Type: application/json
 * to avoid pre-flight
 * @param {string} payload
 * @returns {string}
 * @private
 */
function encodePayload(payload) {
  return payload.replace(/[\u007F-\uFFFF]/g, function (c) {
    return "\\u" + ("0000" + c.charCodeAt(0).toString(16)).substr(-4);
  });
}

function checkStatus(data) {
  if (data.status === 400) {
    throw Error("You reached Weglot limitation. Please upgrade your plan.");
  }
  if (data.status === 401) {
    throw Error("Your Weglot API key seems wrong.");
  }
  if (data.status >= 402) {
    throw Error(data.statusText);
  }
  return data;
}

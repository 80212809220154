import getCurrentLocation from "./getLocation";
import options from "../options/options";
import { getURLParameter } from "../../utils/helpers";
import getLocaleRule from "./localeRules";

let currentLanguage;

export function getSubdomainLanguage() {
  const { hostname } = getCurrentLocation();
  const language = options.languages.find(
    l =>
      l.connect_host_destination && l.connect_host_destination.host === hostname
  );
  if (language) {
    return language.custom_code || language.language_to;
  }

  return options.language_from;
}

export function getSubdirectoryLanguage() {
  return getLocaleRule().language;
}

export function getDocumentLangAttributes(availableLanguages) {
  const lang =
    document.documentElement && document.documentElement.getAttribute("lang");

  if (!lang) {
    return;
  }

  if (availableLanguages.includes(lang)) {
    return lang;
  }
  for (const language in availableLanguages) {
    // There could be a difference between the lang attribute and the language code (en-CA , en-US)
    if (language.slice(0, 2) === lang.slice(0, 2)) {
      return language;
    }
  }
  return null;
}

export function setCurrentLanguage(lang) {
  const langParam = getURLParameter("lang");
  if (langParam && langParam !== lang) {
    const newSearch = window.location.search.replace(
      `lang=${langParam}`,
      `lang=${lang}`
    );
    try {
      window.history.replaceState(
        null,
        "",
        window.location.pathname + newSearch
      );
    } catch (e) {} // eslint-disable-line
  }
  currentLanguage = lang;
}

export default () => {
  if (currentLanguage) {
    return currentLanguage;
  }

  // By default, the current language on JS is the original language
  if (options.is_connect) {
    const { wgTranslated } = document.documentElement.dataset;
    if (wgTranslated) {
      currentLanguage = wgTranslated;
      return wgTranslated;
    }
    currentLanguage = options.subdirectory
      ? getSubdirectoryLanguage()
      : getSubdomainLanguage();
    return currentLanguage;
  }

  if (options.technology_name === "WordPress") {
    const lang = getDocumentLangAttributes(
      options.languages.map(l => l.language_to)
    );
    if (lang) {
      return lang;
    }
  }

  currentLanguage = options.language_from;
  return currentLanguage;
};

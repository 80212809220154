// A set of listener and messaging events that will be used to communicate
// with the Visual Editor extension.

import getCurrentLanguage from "../helpers/getCurrentLanguage";
import { registerEvent } from "../../utils/events";
import {
  EXTENSION_MESSAGE_NAME,
  EXTENSION_MESSAGE_TARGETS,
  EXTENSION_MESSAGE_TYPES,
} from "common/constants";

// Controls all addon events: Only set to true once we receive an initial message
// from the extension listener.
let foundExtension = false;

export function sendToExtension(data) {
  window.postMessage(
    {
      name: EXTENSION_MESSAGE_NAME,
      data,
      target: EXTENSION_MESSAGE_TARGETS.EXTENSION,
    },
    "*"
  );
}

registerEvent("languageChanged", newLanguage => {
  if (!foundExtension) {
    return;
  }
  sendToExtension({
    type: EXTENSION_MESSAGE_TYPES.LANGUAGE_CHANGED,
    payload: newLanguage,
  });
});

export default function addExtensionListener() {
  window.addEventListener("message", message => {
    // @ts-ignore Custome event
    if (!message.data) {
      return;
    }
    const { name, target, data } = message.data;
    if (
      name !== EXTENSION_MESSAGE_NAME ||
      target !== EXTENSION_MESSAGE_TARGETS.LIBRARY
    ) {
      return;
    }
    // @ts-ignore
    if (target !== "library") {
      return;
    }
    foundExtension = true;
    const { type } = data;
    switch (type) {
      case EXTENSION_MESSAGE_TYPES.GET_CURRENT_LANGUAGE: {
        sendToExtension({
          type: EXTENSION_MESSAGE_TYPES.SET_CURRENT_LANGUAGE,
          payload: getCurrentLanguage(),
        });
      }
    }
  });
}

export function extensionPresent() {
  return foundExtension;
}

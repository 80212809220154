export default [
  {
    previous: "cb",
    new: "ceb",
  },
  {
    previous: "hm",
    new: "hmn",
  },
  {
    previous: "sa",
    new: "sr-latn",
  },
];

import URL from "common/helpers/url";

import logger from "./logger";

// Some "polyfill" utils
const tryCatch = (property, errorReturn) => (element, q) => {
  if (!element || !element[property] || !q) {
    return errorReturn;
  }
  try {
    return element[property](q);
  } catch (e) {
    logger.error(e, {
      consoleOverride: `The CSS selectors that you provided are incorrect: ${q}`,
      sendToDatadog: false,
    });
  }
  return errorReturn;
};
export const safeQuerySelectorAll = tryCatch("querySelectorAll", []);
export const safeQuerySelector = tryCatch("querySelector", null);
export const safeClosest = tryCatch("closest", null);

export function concatenate(value, toAdd) {
  if (!toAdd) {
    return value;
  }
  // Check array before object
  if (Array.isArray(value)) {
    return [].concat(value, toAdd);
  }
  if (typeof value === "object") {
    return Object.assign({}, value, toAdd);
  }
  // value is a string selector, clean it
  value = cleanSelector(value);
  return value && value.length > 0 && value !== toAdd
    ? (value += `,${toAdd}`)
    : (value = toAdd);
}

export function unescapeHTMLEntities(string) {
  string = "" + string;
  var toEscape = ["&nbsp;", "&amp;", "&quot;", "&lt;", "&gt;"];
  return toEscape.some(k => string.indexOf(k) !== -1)
    ? string
        .replace(/&nbsp;/g, " ") // non-breaking space
        .replace(/&amp;/g, "&")
        .replace(/&quot;/g, '"')
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
    : string;
}

export const ObjectValues = object => Object.keys(object).map(k => object[k]);

export function removeFinalSlash(string) {
  return (
    string &&
    string.replace &&
    string.replace(/wg-(\d+)=""(\s*)\/(\s*)>/g, 'wg-$1="">')
  );
}

export function getURLParameter(name) {
  const param = window.location.search
    .slice(1)
    .split("&")
    .map(q => q && q.split("="))
    .find(([key]) => key === name);
  return param && param[1];
}

export function inFrame() {
  try {
    if (window.frameElement || window.self !== window.top) {
      return "with-window-top";
    }
  } catch (_) {
    // If it failed we are in iframe but we can't access to window.top, so shouldn't send message.
    return "no-window-top";
  }
}

export function loadRemoteCSS(url) {
  const cssLink = document.createElement("link");
  cssLink.rel = "stylesheet";
  cssLink.type = "text/css";
  cssLink.href = url;
  document.head.appendChild(cssLink);
}

export const isSearchBot = () =>
  /google|facebook|bing|yahoo|baidu|yandex|lighthouse/i.test(
    navigator.userAgent
  );

export function isSelectorValid(selector) {
  try {
    document.createDocumentFragment().querySelector(selector);
  } catch (e) {
    return false;
  }
  return true;
}

export const updateQueryStringParameter = function (uri, key, value) {
  const url = new URL(uri, location.href);
  url.searchParams.set(key, value);
  return `${url.pathname}${url.search}`;
};

export const getCurrentHost = () => {
  const base = document.getElementsByTagName("base")[0];
  const getHostname = () => location.host.split(".");
  if (base) {
    try {
      return new URL(base.href, location.href).host.split(".");
    } catch (e) {
      return getHostname();
    }
  }
  return getHostname();
};

export const debounce = (func, wait = 1000) => {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
};

export const isIframeAccessible = iframe => {
  const time = new Date().getTime().toString();
  try {
    const global = iframe.contentWindow;
    global[time] = "asd";
    return global[time] === "asd";
  } catch (e) {
    return false;
  }
};

export function cleanSelector(selector) {
  return selector
    .split(",")
    .filter(selector => selector)
    .join(",");
}

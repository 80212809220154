import { h } from "preact";

import { getLanguageFlag, getLanguageName } from "../utils";

const FLAG_SIZES = {
  rectangle_mat: { width: 24, height: 16 },
  shiny: { width: 24, height: 16 },
  circle: { width: 20, height: 20 },
  square: { width: 20, height: 20 },
};

const Flag = props => {
  const { language, flagType } = props;
  const code = getLanguageFlag(language);
  const { width, height } = FLAG_SIZES[flagType];
  if (!code) {
    return;
  }
  const src =
    code.indexOf("http") > -1
      ? code
      : `https://cdn.weglot.com/flags/${flagType}/${code}.svg`;
  return (
    <img
      src={src}
      width={width}
      height={height}
      className="wg-flag"
      alt={`${getLanguageName(language)} flag`}
    />
  );
};

export default Flag;

import options from "../options/options";
import logger from "../../utils/logger";
import isExcludedPath from "./isExcludedPath";
import { registerEvent } from "../../utils/events";

let availableLanguages;

registerEvent(
  "onCurrentLocationChanged",
  () => {
    availableLanguages = null;
  },
  true
);

export default function getAvailableLanguagesList() {
  if (availableLanguages) {
    return availableLanguages;
  }
  if (!options.api_key) {
    logger.warn("Weglot must be initialized to use it.", {
      sendToDatadog: false,
    });
    return [];
  }
  const languages = (options.languages || [])
    .filter(l => {
      const excluded = isExcludedPath(l.language_to);
      const showInSwitcher = !excluded || excluded.language_button_displayed;
      return (
        (l.enabled !== false || options.private_mode) &&
        showInSwitcher &&
        (options.subdirectory ||
          !options.is_connect ||
          (l.connect_host_destination &&
            l.connect_host_destination.created_on_aws))
      );
    })
    .map(l => l.custom_code || l.language_to);
  const langs = [options.language_from].concat(languages);
  availableLanguages = langs.filter(
    (elem, pos) => elem && langs.indexOf(elem) == pos
  );
  if (!languages.length) {
    logger.log("No public language available.", { sendToDatadog: false });
  }
  return availableLanguages;
}

import { getFromID, addStyleToHead, removeElement } from "common/helpers/utils";

import { transitionStyleId } from "../constants";

// Add a style to head for waitTransition
export function addStyleForTransition() {
  addStyleToHead(
    "@keyframes wg{from{color:transparent;}to{color:transparent;}}body *{color:transparent!important;animation:1s linear infinite wg!important;}",
    transitionStyleId
  );
}

// Removes the waitTransition style
export function removeStyleForTransition() {
  removeElement(getFromID(transitionStyleId));
}

import getCurrentLanguage from "./getCurrentLanguage";
import getCurrentLocation from "./getLocation";
import getInternalLanguageCode from "./getInternalLanguageCode";
import options from "../options/options";
import logger from "../../utils/logger";

const cache = {};

export function pathTests(path) {
  return {
    START_WITH: val => path.indexOf(val) === 0,
    NOT_START_WITH: val => path.indexOf(val) !== 0,
    END_WITH: val => path.indexOf(val, path.length - val.length) !== -1,
    NOT_END_WITH: val => path.indexOf(val, path.length - val.length) === -1,
    CONTAIN: val => path.indexOf(val) !== -1,
    NOT_CONTAIN: val => path.indexOf(val) === -1,
    IS_EXACTLY: val => path === val,
    NOT_IS_EXACTLY: val => path !== val,
    MATCH_REGEX: val => {
      try {
        return new RegExp(val, "i").test(path);
      } catch (e) {
        logger.warn(e, {
          consoleOverride: `${val} is an invalid regex`,
          sendToDatadog: false,
        });
        return false;
      }
    },
  };
}

function getPath(path) {
  if (path) {
    return path;
  }
  if (options.injectedData && options.injectedData.originalPath) {
    return options.injectedData.originalPath;
  }
  return getCurrentLocation().pathname;
}

// Checks if current path is excluded and returns:
// (a): path excluded => object specifying if all langs excluded and switcher hidden.
// (b): path not excluded => false/undefined
// For v1/2 we default to show the switcher
function getExclusion(language, path) {
  const { excluded_paths } = options;

  // legacy setting: excluded_paths: '/excluded,/another'
  if (typeof excluded_paths === "string") {
    if (excluded_paths.split(",").some(v => new RegExp(v, "i").test(path))) {
      return {
        allExcluded: true,
        language_button_displayed: true,
      };
    }
    return null;
  }

  const foundRule = excluded_paths.find(rule => {
    const { type, value, excluded_languages } = rule;

    // Check if current language is excluded
    if (
      excluded_languages &&
      excluded_languages.length &&
      !excluded_languages.includes(language)
    ) {
      return;
    }

    if (pathTests(path)[type](value)) {
      return rule;
    }

    let decodedPath = path;
    try {
      decodedPath = decodeURIComponent(path);
    } catch (_) {
      return;
    }

    if (decodedPath === path) {
      return;
    }

    // Pathname was encoded, so check if decoded path matches
    if (pathTests(decodedPath)[type](value)) {
      return rule;
    }
  });
  if (!foundRule) {
    return null;
  }
  return {
    language_button_displayed: foundRule.language_button_displayed,
    allExcluded:
      !foundRule.excluded_languages.length ||
      foundRule.excluded_languages.length >= options.languages.length,
  };
}

export default function isExcludedPath(lang, path) {
  const { excluded_paths } = options;
  if (!excluded_paths || !excluded_paths.length) {
    return false;
  }

  path = getPath(path).toLowerCase();
  lang = getInternalLanguageCode(lang || getCurrentLanguage());

  // Check cache
  if (cache[path] && cache[path][lang]) {
    return cache[path][lang];
  }

  // Create cache
  if (!cache[path]) {
    cache[path] = {};
  }

  // Get exclusion & update cache
  const exclusion = getExclusion(lang, path);
  cache[path][lang] = exclusion;
  return exclusion;
}

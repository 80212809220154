import { getFromID, removeElement } from "common/helpers/utils";

// Creates a progress bar, starts the "automatic update" setInterval, and returns it
export function createProgressBar() {
  try {
    var progressBar = document.createElement("div");
    progressBar.className = "wg-progress";
    progressBar.id = "wg_progress";
    progressBar.innerHTML =
      '<div class="wg-progress-bar" role="progressbar" aria-label="Page is loading"></div>';
    document.body.appendChild(progressBar);
    /* eslint-disable-next-line */
  } catch (error) {}
}

// Remove the progress bar by stopping the "interval" and removing the element
export function removeProgressBar() {
  removeElement(getFromID("wg_progress"));
}

import options from "../options/options";
// @ts-ignore
import { languages } from "common/data/languagesByCode.json";
import migratedLanguages from "common/data/migratedLanguages.js";

export default function getLanguageName(code) {
  if (!code || !code.toLowerCase) {
    return "Unknown";
  }

  const migratedLanguage = migratedLanguages.find(ml => ml.previous === code);
  if (migratedLanguage) {
    code = migratedLanguage.new;
  }

  const lowerCode = code.toLowerCase();
  const language = options.languages.find(({ language_to, custom_code }) => {
    if (language_to === lowerCode) {
      return true;
    }
    if (custom_code) {
      return custom_code.toLowerCase() === lowerCode;
    }
  });
  if (language && language.custom_local_name) {
    return language.custom_local_name;
  }
  if (language && language.custom_name) {
    return language.custom_name;
  }
  if (
    lowerCode === options.language_from &&
    options.language_from_custom_name
  ) {
    return options.language_from_custom_name;
  }
  return languages[lowerCode] ? languages[lowerCode].name : "Unknown";
}

import logger from "../../utils/logger";
import { cacheSlugs } from "../constants";
import options from "../options/options";
import storage from "../../utils/storage";

let data = {
  slugs: {}, // all slugs
  version: 0, // current slug version
  network: undefined, // network request, if needed
};
getCache();

function query(language_to) {
  const { api_key, versions } = options;
  if (!versions || !versions.slugTranslation) {
    return Promise.resolve({});
  }
  const url = `https://CDN_API_HOST/translations/slugs?api_key=${api_key}&language_to=${language_to}&v=${versions.slugTranslation}`;
  return fetch(url)
    .then(res => res.json())
    .then(slugs => (Array.isArray(slugs) ? {} : slugs))
    .catch(_ => ({})); // eslint-disable-line
}

function fetchSlugs() {
  return new Promise(res => {
    const { languages } = options;
    const allSlugs = {};
    for (const { custom_code, language_to } of languages) {
      query(language_to).then(slugs => {
        allSlugs[custom_code || language_to] = slugs;
        if (Object.keys(allSlugs).length === languages.length) {
          res(allSlugs);
        }
      });
    }
  });
}

function getCache() {
  if (Object.keys(data.slugs).length) {
    return data.slugs;
  }
  try {
    const store = storage({ type: "local" });
    if (!store) {
      return {};
    }
    const cached = store.getItem(cacheSlugs);
    if (!cached) {
      return {};
    }
    Object.assign(data, JSON.parse(cached));
    return data.slugs;
  } catch (e) {
    return {};
  }
}

function setCache(slugs) {
  const { versions } = options;
  const cache = {
    version: versions ? versions.slugTranslation : 1,
    slugs,
  };
  try {
    // Only put slugs in localStorage, never in cookies.
    const store = storage({ type: "local" });
    if (store) {
      store.setItem(cacheSlugs, JSON.stringify(cache));
    }
  } catch (e) {
    logger.warn(e);
  }
  // Also add directly to data (i.e., if localStorage not available)
  data = { ...data, ...cache };
}

function getFormattedSlugs(slugs) {
  return Object.keys(slugs).reduce(
    (formatted, original) => {
      // Put slug in original/translated objects if slug exists
      if (slugs[original]) {
        formatted.original[original] = slugs[original];
        formatted.translated[slugs[original]] = original;
      }
      return formatted;
    },
    {
      original: {},
      translated: {},
    }
  );
}

function normalizeSlugs(slugs) {
  if (!slugs) {
    return {};
  }
  return Object.keys(slugs).reduce((formatted, language) => {
    formatted[language] = getFormattedSlugs(slugs[language]);
    return formatted;
  }, {});
}

export default function getSlugs(callback) {
  const { versions } = options;
  if (!versions || !versions.slugTranslation) {
    callback({});
    return;
  }
  const version = versions.slugTranslation;
  // If data is outdated
  if (data.version < version) {
    // Fetch slugs only once
    if (!data.network) {
      data.network = fetchSlugs()
        .then(slugs => {
          data.network.resolved = true;
          setCache(slugs);
          callback(normalizeSlugs(slugs));
          return slugs;
        })
        .catch(() => {
          callback({});
          return {};
        });
    } else if (!data.network.resolved) {
      // if request not done yet, attach a promise to update with last version
      data.network.then(slugs => callback(normalizeSlugs(slugs)));
    }
  }
  callback(normalizeSlugs(data.slugs));
}

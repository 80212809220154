import { resize } from "../../shared/utils";
function getCss({ style: { size_scale } }) {
  const scale = initial => resize(initial, size_scale);
  return [
    {
      selector: "",
      declarations: {
        position: "relative",
      },
    },
    {
      selector: " .language-list",
      declarations: {
        position: "absolute",
        display: "flex",
        width: "100%",
        justifyContent: "center",
      },
    },
    {
      selector: ".wg-up .language-list",
      declarations: {
        bottom: "0px",
        top: "auto",
        flexDirection: "column-reverse",
        marginBottom: `${scale(40)}px`,
        borderTopLeftRadius: `${scale(5)}px`,
        borderTopRightRadius: `${scale(5)}px`,
      },
    },
    {
      selector: ".wg-down .language-list",
      declarations: {
        bottom: "auto",
        top: "0",
        flexDirection: "column",
        marginTop: `${scale(40)}px`,
        borderBottomLeftRadius: `${scale(5)}px`,
        borderBottomRightRadius: `${scale(5)}px`,
      },
    },
    {
      selector: ".wg-up .active-language",
      declarations: {
        borderBottomLeftRadius: `${scale(5)}px`,
        borderBottomRightRadius: `${scale(5)}px`,
      },
    },
    {
      selector: ".wg-down .active-language",
      declarations: {
        borderTopLeftRadius: `${scale(5)}px`,
        borderTopRightRadius: `${scale(5)}px`,
      },
    },
    {
      selector: " .language-option",
      declarations: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        fontFamily:
          "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
        transition: "height .2s, opacity 0.2s",
        fontSize: `${scale(14)}px`,
        height: `${scale(40)}px`,
        padding: `0px ${scale(15)}px`,
      },
    },
    {
      selector: ".closed .language-list > .language-option",
      declarations: {
        height: "0px",
        pointerEvents: "none",
        opacity: "0",
      },
    },
    {
      selector: ".wg-up .language-option:last-child",
      declarations: {
        borderTopLeftRadius: `${scale(5)}px`,
        borderTopRightRadius: `${scale(5)}px`,
      },
    },
    {
      selector: ".wg-down .language-option:last-child",
      declarations: {
        borderBottomLeftRadius: `${scale(5)}px`,
        borderBottomRightRadius: `${scale(5)}px`,
      },
    },
    {
      selector: ".closed .active-language",
      declarations: {
        borderRadius: `${scale(5)}px`,
      },
    },
    {
      selector: " .language-option a",
      declarations: {
        outline: "none",
        textDecoration: "none",
        display: "flex",
        fontWeight: "700",
        gap: `${scale(10)}px`,
        alignItems: "center",
      },
    },
    {
      selector: " .language-option a img",
      declarations: {
        overflowClipMargin: "content-box",
        overflow: "clip",
        verticalAlign: "middle",
        objectFit: "cover",
        display: "inline-block",
        border: "0",
        width: `${scale(20)}px`,
        height: `${scale(16)}px`,
      },
    },
  ];
}

export default getCss;

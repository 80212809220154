// @ts-ignore
import technologySettings from "@weglot/technology-rules/dist/technologies.client.json";
import getCurrentLocation from "../helpers/getLocation";
import options from "../options/options";
import { pathTests } from "../helpers/isExcludedPath";
import { inFrame, safeQuerySelector } from "../../utils/helpers";

export default function getTechnologyRules(technologyOptions, technology_id) {
  const technology = technologySettings.find(
    technologySetting => technologySetting.technology_id === technology_id
  );
  if (technology && technology.settings) {
    technology.settings
      .filter(({ condition }) => definitionChecker(condition))
      .map(({ value }) =>
        value.map(override => {
          mergeSetting(technologyOptions, override.type, override.payload);
        })
      );
  }
  return technologyOptions;
}

export function technologyIdChecker(technology_id) {
  return !technology_id || technology_id === options.technology_id;
}

export function definitionChecker(conditions) {
  if (!conditions || conditions.length === 0) {
    return true;
  }
  if (!Array.isArray(conditions)) {
    return false;
  }

  const currentUrl = getCurrentLocation();

  for (const condition of conditions) {
    //if many conditions are in an array, all of them must be true (AND)
    //if not every of them are true, continue to the next condition if it exists (OR)
    if (Array.isArray(condition)) {
      if (
        condition.every(c => {
          return conditionChecker(c, currentUrl);
        })
      ) {
        return true;
      }
      //if only one condition, it must be true (OR)
    } else if (conditionChecker(condition, currentUrl)) {
      return true;
    }
  }

  return false;
}

function conditionChecker(condition, currentUrl) {
  if (!condition) {
    return true;
  }

  const { type, payload } = condition;

  if (!payload && typeof payload !== "boolean") {
    return true;
  }

  if (type === "URI_MATCH" && !!stringMatches(currentUrl.url, payload)) {
    return true;
  }

  if (type === "HOST_MATCH" && !!stringMatches(currentUrl.hostname, payload)) {
    return true;
  }

  if (
    type === "TRANSLATION_URL_MATCH" &&
    !!stringMatches(currentUrl.url, payload)
  ) {
    return true;
  }

  if (type === "PATH_MATCH" && !!stringMatches(currentUrl.pathname, payload)) {
    return true;
  }

  if (type === "INTEGRATION") {
    return (
      (payload === "proxy" && options.is_connect) ||
      (payload === "javascript" && !options.is_connect)
    );
  }

  if (type === "DOM_CONTAINS") {
    const { selector } = payload;
    if (safeQuerySelector(document, selector)) {
      return true;
    }
  }

  if (type === "IN_FRAME") {
    return payload === (inFrame() === "with-window-top");
  }

  const isXmlPayload = typeof payload === "object" && "selector" in payload;
  if (
    !isXmlPayload ||
    !["XML_ATTRIBUTE_VALUE", "XML_ROOT_ELEMENT_NAME"].includes(type)
  ) {
    return false;
  }

  return false;
}

function mergeSetting(technologyOptions, type, value) {
  const oldValue = technologyOptions[type];

  if (oldValue === undefined) {
    technologyOptions[type] = value;
    return;
  }
  if (Array.isArray(oldValue) && Array.isArray(value)) {
    technologyOptions[type] = [...oldValue, ...value];
    return;
  }
  if (Array.isArray(value) && typeof oldValue === "string") {
    technologyOptions[type] = [...value, oldValue.split(",")];
    return;
  }
  if (typeof oldValue === "object") {
    technologyOptions[type] = { ...oldValue, ...value };
    return;
  }
  technologyOptions[type] = value;
}

const stringMatches = (str, rule) => {
  const { type, value } = rule;
  str = str.toLocaleLowerCase();
  return pathTests(str)[type](value);
};

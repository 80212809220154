import { getTextNodes, setTextNodes } from "html-parser-engine";

import { unescapeHTMLEntities, removeFinalSlash } from "../../utils/helpers";
import logger from "../../utils/logger";
import { ObjectValues } from "../../utils/helpers";
import getCurrentLanguage from "../helpers/getCurrentLanguage";
import { isCachedWord } from "../query/translationCache";
import options from "../options/options";
import { removeEncodings } from "../helpers/removeEncoding";

const allElements = [];
const seenWordsTo = new Set();
let listedConnectWords = false;

// Store list of phrases received from API in order to prevent re-sending them for
// translation. A phrase is considered unique up to HTML tags and whitespace.
const duplicates = {
  check: w => seenWordsTo.has(removeEncodings(w)),
  add: w => seenWordsTo.add(removeEncodings(w)),
};

export function parseNodes(parent = document.documentElement, predicate) {
  const currentLanguage = getCurrentLanguage();
  // Get only new nodes or unknown words
  return getTextNodes(parent)
    .filter(node => (predicate ? predicate : getUnknownNodes)(node))
    .map(setNodesProperties(currentLanguage));
}

function getUnknownNodes({ element, words }) {
  if (!element.weglot || !element.weglot.content) {
    return true;
  }
  return !element.weglot.content.some(
    ({ original, translations }) =>
      original === words ||
      ObjectValues(translations).includes(unescapeHTMLEntities(words))
  );
}

function setNodesProperties(currentLanguage) {
  return function ({ element, words, type, properties, attrSetter }) {
    if (!element.weglot) {
      element.weglot = {
        content: [],
      };
    }
    const { weglot } = element;

    // check if original is already a translation
    const translations = {};
    const original = isCachedWord(words, currentLanguage);
    if (original) {
      translations[currentLanguage] = words;
      words = original;
    }

    // if new words with html content
    if (properties) {
      const content = weglot.content.find(({ html }) => html);
      if (content) {
        Object.assign(content, {
          original: words,
          properties,
          translations,
        });
      } else {
        weglot.content.push({
          html: true,
          original: words,
          type,
          properties,
          translations,
        });
      }
    }
    // if new words with attribute content
    if (attrSetter) {
      const content = weglot.content.find(c => c.attrSetter === attrSetter);
      const newContent = {
        attrSetter,
        original: words,
        type,
        translations,
      };
      if (content) {
        Object.assign(content, newContent);
      } else {
        weglot.content.push(newContent);
      }
    }
    return element;
  };
}

export function getNodes() {
  return allElements;
}

export function addNodes(elements) {
  const newNodes = [];
  for (const element of elements) {
    if (allElements.indexOf(element) === -1) {
      newNodes.push(element);
    }
  }
  [].push.apply(allElements, newNodes);
  return newNodes;
}

/**
 * Get only & unique words, without elements or type
 */
export function getPayload(elements = allElements, opts = {}) {
  const { prevent_retranslation, injectedData = {}, is_connect } = options;
  if (prevent_retranslation && is_connect && !listedConnectWords) {
    // Add Connect translation list for duplicates check
    const { translatedWordsList = [] } = injectedData;
    translatedWordsList.forEach(w => duplicates.add(w));
    listedConnectWords = true;
  }
  const payload = [];
  const added = {};
  for (const { weglot, translationLabel } of elements) {
    for (const { original, type } of weglot.content) {
      if (added[original]) {
        // Word is already in the current payload, so skip it.
        continue;
      }
      if (prevent_retranslation && duplicates.check(original)) {
        // Word is one we already received from the API: Consider as duplicate.
        continue;
      }
      added[original] = true;
      payload.push({
        t: type,
        w: original,
        ...(opts.label || translationLabel
          ? {
              l: [opts.label, translationLabel]
                .filter(Boolean)
                .map(label => label.slice(0, 255)),
            }
          : {}),
      });
    }
  }
  return payload;
}

export function setWords(
  words,
  targetLang = getCurrentLanguage(),
  elements = allElements
) {
  if (words && words.to_words && words.to_words.length) {
    const { from_words, to_words } = words;
    for (const { weglot } of elements) {
      for (const { original, translations } of weglot.content || {}) {
        const index = from_words.indexOf(unescapeHTMLEntities(original));
        if (index === -1) {
          continue;
        }
        if (!translations[targetLang]) {
          const final = removeFinalSlash(to_words[index]);
          if (options.prevent_retranslation) {
            duplicates.add(final);
          }
          translations[targetLang] = final;
        }
      }
    }
  }
  try {
    setTextNodes(elements, targetLang);
  } catch (e) {
    logger.error(e);
  }
}

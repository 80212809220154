import options from "../../weglot/options/options";
import { registerEvent } from "../../utils/events";
import getCurrentLocation from "./getLocation";

let rule = {};

// Takes a regex string and replaces regex capturing groups with position integers $n.
function getRegexPositions(str) {
  let n = 1;
  const regex = /\((.*?)\)/g;
  return str.replace(regex, () => `$${n++}`);
}

// Default export, used for subdirectory URLs to detect and apply the rule for converting
// locale codes between languages.
function getLocaleRule() {
  const { pathname } = getCurrentLocation();
  const { localeRules = [], languages } = options;
  const defaultRule = {
    position: 0,
    translatedFormat: "CODE",
    originalFormat: "",
    addedByDefault: true,
  };
  const originalLanguage = options.language_from;
  // Edge case when we have Shopify Markets type locale and a custom language including a "-".
  // Create defaultRules array and give it first priority.
  if (localeRules.length) {
    let activePositions = [];
    localeRules.map(({ position, translatedFormat }) => {
      if (translatedFormat && translatedFormat !== "CODE") {
        activePositions.push(position || 0);
      }
    });
    const defaultRules = activePositions
      .filter((value, index, self) => self.indexOf(value) === index) // unique only
      .map(position => ({
        ...defaultRule,
        position,
      }));
    localeRules.unshift(...defaultRules);
  } else {
    localeRules.push(defaultRule);
  }
  let detectedLanguage = null;
  let translationRegex = null;
  const localeRule =
    localeRules.find(
      ({
        position = 0,
        translatedFormat = "CODE",
        originalFormat = "",
        addedByDefault,
      }) => {
        if (!translatedFormat.includes("CODE")) {
          // localeRule is invalid -> reject
          return false;
        }
        const splitPathname = pathname.split("/");
        if (splitPathname.length <= position) {
          // URL path doesn't have enough subdirs to match the rule => reject
          return false;
        }
        const urlLocale = splitPathname[position + 1];
        const translationLanguage = languages.find(l => {
          const code = l.custom_code || l.language_to;
          const formattedCode = translatedFormat.replace("CODE", code);
          const regex = new RegExp(`^${formattedCode}$`, "g");
          if (regex.test(urlLocale)) {
            translationRegex = regex;
            return true;
          }
          return false;
        });
        if (translationLanguage) {
          detectedLanguage =
            translationLanguage.custom_code || translationLanguage.language_to;
          return true;
        }
        if (originalFormat) {
          const originalCode = originalFormat.replace("CODE", originalLanguage);
          const originalRegex = new RegExp(`^${originalCode}$`, "g");
          return originalRegex.test(urlLocale);
        }
        // URL doesn't match any translatedFormat and the rule has no originalFormat.
        // If this rule was added by default, keep checking the remaining rules. Otherwise always accept it.
        return !addedByDefault;
      }
    ) || defaultRule;
  rule.convertLocale = (
    langTo,
    path = pathname,
    langFrom = detectedLanguage || originalLanguage,
    originalRequestPath = null
  ) => {
    // Case 1: langTo === langFrom
    if (langFrom === langTo) {
      return path;
    }
    const {
      position = 0,
      originalFormat = "",
      translatedFormat = "CODE",
    } = localeRule;
    const splitPath = path.split("/");
    if (splitPath.length <= position) {
      return path;
    }
    const pathLocale = splitPath[position + 1];
    // Case 1: langFrom original language, langTo translation
    if (langFrom === originalLanguage) {
      let formattedLocale = translatedFormat.replace(/CODE/g, langTo);
      let useOverride = false;
      if (originalFormat) {
        // If we had originalFormat, check for regex in translatedFormat.
        const originalCode = originalFormat.replace(/CODE/g, originalLanguage);
        const originalRegex = new RegExp(`^${originalCode}$`, "g");
        const regexPositions = getRegexPositions(formattedLocale);
        formattedLocale = pathLocale.replace(originalRegex, regexPositions);
        if (originalRequestPath && !originalRegex.test(pathLocale)) {
          // If originalRegex doesn't match, use the page url locale.
          useOverride = true;
          formattedLocale = originalRequestPath.split("/")[position + 1];
        }
      }
      const shift = originalFormat && !useOverride ? 2 : 1;
      return [
        ...splitPath.slice(0, position + 1),
        formattedLocale,
        ...splitPath.slice(position + shift),
      ].join("/");
    }
    // Case 2: langFrom is translation, langTo original + without locale code
    if (langTo === originalLanguage) {
      if (!originalFormat) {
        return [
          ...splitPath.slice(0, position + 1),
          ...splitPath.slice(position + 2),
        ].join("/");
      }
    }
    // Case 3: langTo has a locale code, either original language or translation.
    const localeFormat =
      langTo === originalLanguage ? originalFormat : translatedFormat;
    const regexPositions = getRegexPositions(
      localeFormat.replace(/CODE/g, langTo)
    );
    const formattedLocale = pathLocale.replace(
      translationRegex,
      regexPositions
    );
    return [
      ...splitPath.slice(0, position + 1),
      formattedLocale,
      ...splitPath.slice(position + 2),
    ].join("/");
  };
  rule.language = detectedLanguage || originalLanguage;
  return rule;
}

registerEvent(
  "onCurrentLocationChanged",
  () => {
    rule = {};
  },
  true
);

export default getLocaleRule;

export const getFromID = id => document.getElementById(id);

export const getInjectedWeglotData = () => {
  const dataElement = getFromID("weglot-data");
  if (!dataElement) {
    return null;
  }
  try {
    return JSON.parse(dataElement.textContent);
  } catch (_) {
    return null;
  }
};

export function createInlineStyle(contents) {
  const style = document.createElement("style");
  style.appendChild(document.createTextNode(contents));
  return style;
}

export function createStyleSheet(src) {
  const link = document.createElement("link");
  link.setAttribute("rel", "stylesheet");
  link.setAttribute("href", src);
  return link;
}

export function addStyleToHead(css, id) {
  const style = document.createElement("style");
  removeElement(getFromID(id));
  style.id = id;
  style.type = "text/css";
  if (style["styleSheet"]) {
    style["styleSheet"].cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
  if (document.head) {
    document.head.appendChild(style);
  }
}

export function removeElement(obj) {
  if (obj && obj.parentNode) {
    obj.parentNode.removeChild(obj);
  }
}

export function loadRemoteScript(url, callback, arg) {
  let done = false;
  function handleLoad() {
    if (!done) {
      done = true;
      setTimeout(() => callback(arg), 20);
    }
  }
  const head =
    document.getElementsByTagName("head")[0] || document.documentElement;
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = url;
  if (script.addEventListener) {
    script.addEventListener("load", handleLoad, false);
    script.addEventListener("error", handleLoad, false);
  } else if (script["readyState"]) {
    script["onreadystatechange"] = handleLoad;
  }
  head.insertBefore(script, head.firstChild);
}

export function safeQuerySelector(element, selector) {
  try {
    return element.querySelector(selector);
  } catch (e) {
    return null;
  }
}

export function getHreflangLink(lang) {
  let link = document.querySelector(`link[hreflang*="${lang}"]`);
  if (!link) {
    // Try to find the language with the shortened code (e.g. "en-UK" instead of "en-US")
    const langShortened = lang.slice(0, 2);
    link = document.querySelector(`link[hreflang*="${langShortened}"]`);
  }
  return link && link.href;
}
